import React, { useState } from "react";
import { CopyBlock, anOldHope } from "react-code-blocks";
import Navigation from "../Navigation";
import SEOHelmet from "../../utils/SEOHelmet";
import downloadIcon from "../../assets/images/download.png";
import defaultRuntime from "../../assets/images/default-runtime.png";
import customRuntime from "../../assets/images/custom-runtime.png";
import stageMlflowModel from "../../assets/images/stage-mlflow-model.png";
import CollapsibleSection from "../../utils/CollapsibleSection";



function MlflowModel() {
    const codeSnippets = {
        tensorflow: `import mlflow.tensorflow
import tensorflow as tf

model = tf.keras.Sequential([
    tf.keras.layers.Dense(10, activation='relu'),
    tf.keras.layers.Dense(1, activation='sigmoid')
])
model.compile(optimizer='adam', loss='binary_crossentropy')
model.fit([[0.1], [0.2], [0.3]], [[1], [0], [1]], epochs=10)

mlflow.tensorflow.log_model(model, "tensorflow_model")`,
        pytorch: `import mlflow.pytorch
import torch.nn as nn

class SimpleModel(nn.Module):
    def __init__(self):
        super(SimpleModel, self).__init__()
        self.fc = nn.Linear(10, 1)

    def forward(self, x):
        return self.fc(x)

model = SimpleModel()
mlflow.pytorch.log_model(model, "pytorch_model")`,
        xgboost: `import mlflow.xgboost
import xgboost as xgb
import numpy as np

dtrain = xgb.DMatrix(np.random.rand(100, 10), label=np.random.randint(2, size=100))
model = xgb.train({"objective": "binary:logistic"}, dtrain, num_boost_round=10)

mlflow.xgboost.log_model(model, "xgboost_model")`,
        sklearn: `import mlflow.sklearn
from sklearn.linear_model import LogisticRegression

model = LogisticRegression()
model.fit([[0, 1], [1, 0]], [0, 1])

mlflow.sklearn.log_model(model, "sklearn_model")`,
        onnx: `import mlflow.onnx

onnx_model_path = "model.onnx"
mlflow.onnx.log_model(onnx_model_path, "onnx_model")`,
        vipasSDK: `from vipas.mlflow import ModelClient
# Fetch configuration files
config_files_path = ModelClient.mlflow_configuration_files(
    model_id="your-model-id",  # Unique identifier for your model
    custom_runtime=False      # Set to True for custom runtime configurations
)
    
print(f"Configuration files saved at: {config_files_path}")`,
        defaultDocker: `# Use a Python base image
FROM python:3.10.12
# Set the working directory
WORKDIR /app
# Copy all necessary files
COPY model-settings.json /app/model-settings.json
COPY model/ /app/model
COPY model/requirements.txt /app/model/requirements.txt
# Install required Python packages
RUN pip install --no-cache-dir -r /app/model/requirements.txt
RUN pip install --no-cache-dir mlserver-mlflow mlserver
EXPOSE 8080
CMD ["mlserver", "start", "/app"]
`,
        defaultModelSettings: `
{
  "name": "<model-service-name>",//provided by vipas
  "implementation": "mlserver_mlflow.MLflowRuntime",
  "parameters": {{
      "uri": "/app/model"
  }}
}`,
        customModelSetting: `
{
  "name": "<model-service-name>",  // Provided by Vipas.AI
  "implementation": "custom_runtime.CustomMLflowRuntime",
  "parameters": {
      "uri": "/app/model"
  }
}`,
        customRuntime: `
from mlserver_mlflow import MLflowRuntime
import pandas as pd
from mlserver.types import ResponseOutput, InferenceResponse

class CustomMLflowRuntime(MLflowRuntime):
    async def predict(self, payload):
        # Decode the input payload
        decoded_payload = self.decode_request(payload)

        # Access the input data from the InferenceRequest object
        input_data = decoded_payload.inputs[0].data

        # Convert the input data to a pandas DataFrame
        input_data = pd.DataFrame([input_data])  # Wrap data in a list to create a single row DataFrame

        # Debug: Print the converted DataFrame
        print("Input DataFrame:", input_data)

        # Perform inference using the model
        model_output = self._model.predict(input_data)

        # Debug: Print the model output
        print("Model Output:", model_output)

        # Convert the model output to a list (use NumPy's tolist if necessary)
        model_output_list = model_output.values.tolist() if isinstance(model_output, pd.DataFrame) else model_output.tolist()

        # Prepare the response (ensure the "name" field is a string)
        response_output = ResponseOutput(
            name="predict",  # Set a valid string for the output name
            shape=model_output.shape if hasattr(model_output, "shape") else [len(model_output_list)],
            datatype="FP32",  # Adjust datatype as needed
            data=model_output_list  # Convert output to a list
        )

        # Return the properly formatted inference response
        return InferenceResponse(model_name=self.name, outputs=[response_output])`,
        customRuntimeDocker: `
# Use a Python base image
FROM python:3.10.12
# Set the working directory
WORKDIR /app
# Copy all necessary files
COPY model-settings.json /app/model-settings.json
COPY model/ /app/model
COPY model/requirements.txt /app/model/requirements.txt
COPY custom_runtime.py /app/custom_runtime.py
# Install required Python packages
RUN pip install --no-cache-dir -r /app/model/requirements.txt
RUN pip install --no-cache-dir mlserver-mlflow mlserver
EXPOSE 8080
CMD ["mlserver", "start", "/app"]
`,
        customRuntimeModelSettings: `
{
  "name": "<model-service-name>",//provided by vipas
  "implementation": "custom_runtime.CustomMLflowRuntime",
  "parameters": {{
      "uri": "/app/model"
  }}
}
`,
        packageInstallCommand: `RUN pip install --no-cache-dir <additional-package>`,
        dockerBuild: `docker build -t <docker-username>/<image-name>:<tag> .`,
        dockerPush: `docker push <docker-username>/<image-name>:<tag>`,
        vipasPublish: `from vipas.model import ModelClient

# Publish the model
ModelClient.publish_model(
    model_id="your-model-id",  # Replace with your model's unique ID
    mlflow_config_path="path-to-your-docker-json-file"  # Path to the Docker image configuration JSON
)`,
        mlflowConfigPath: `{
  "docker_image": "<docker-username>/<image-name>:<tag>",
  "docker_token": "docker_pat_token"
}`,
        movieRecommendInput: `{
    "inputs": [
        {
            "name": "user_id",
            "shape": [1],
            "datatype": "INT32",
            "data": [3]
        }
    ]
}`,
    };
    return (
        <>
            <SEOHelmet
                title={"MLflow"}
                description={
                    "MLflow is an open-source platform designed to streamline and manage the entire machine learning lifecycle."
                }
                canonicalUrl={
                    "https://docs.vipas.ai/developer-docs/mlflow-model"
                }
            />
        
            <div className="flex my-8 relative">
                <div className="w-[100%] xl:w-[66.66%] px-[24px]">
                    <h1 className="mb-3 heading">MLflow</h1>
                    <p className="my-2">
                        {" "}
                        <a
                            href="https://mlflow.org/docs/latest/models.html"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="links !text-[16px]"
                        >
                            MLflow
                        </a>{" "}
                        is an open-source platform designed to streamline and
                        manage the entire machine learning lifecycle. It
                        provides tools for experiment tracking, model
                        versioning, packaging, and deploying machine learning
                        models. With its simple interface and flexible design,
                        MLflow helps data scientists and ML engineers track
                        metrics, organize experiments, and seamlessly deploy
                        models into production. Serving MLflow using Docker
                        simplifies the deployment process, allowing you to
                        containerize the MLflow server along with its
                        dependencies. This approach ensures consistency across
                        environments, scalability for serving models, and ease
                        of deployment in cloud or on-premises infrastructures.
                        By leveraging Docker, MLflow can be run on VIPAS.AI as a
                        standalone service or integrated into larger workflows,
                        ensuring a robust and efficient ML deployment pipeline.
                    </p>
                    <div className="my-6" id="supported-file-types">
                        <h2 className="sub-heading">Supported File Types</h2>
                        <p className="my-2">
                            Vipas.AI requires users to input a Docker image and
                            a PAT (Personal Access Token) that contains the
                            logged model and all the necessary files for
                            deployment and inference. These files ensure the
                            proper configuration of the model.
                        </p>
                    </div>
                    <div className="my-6" id="directory-structure">
                        <h2 className="sub-heading">Directory Structure</h2>
                        <div className="">
                            <p className="font-semibold">model/</p>
                            <p>&emsp;├── MLmodel</p>
                            <p>&emsp;├── model.pkl</p>
                            <p>&emsp;├── conda.yaml</p>
                            <p>&emsp;├── python_env.yaml</p>
                            <p>&emsp;├── requirements.txt</p>
                            <p>
                                &emsp;├── input_example.json (optional, only
                                logged when input example is provided and valid
                                during model logging)
                            </p>
                            <p>
                                &emsp;├── serving_input_example.json (optional,
                                only logged when input example is provided and
                                valid during model logging)
                            </p>
                            <p>
                                &emsp;├── environment_variables.txt (optional,
                                only logged when environment variables are used
                                during model inference)
                            </p>
                            <p>&emsp;├── Dockerfile</p>
                            <p>&emsp;└── model-settings.json</p>
                        </div>
                    </div>
                    <div className="my-6" id="model-configuration">
                        <h2 className="sub-heading">Model Configuration</h2>
                        <p className="my-2">
                            To log and configure a model in MLflow for
                            deployment on Vipas.AI, follow these steps:
                        </p>
                        <ol className="list-decimal p-4">
                            <li>
                                <strong>Log the Model </strong>
                                <p className="my-2">
                                    {" "}
                                    Logging models in MLflow involves capturing
                                    the model's structure, parameters,
                                    input/output signatures, and artifacts for
                                    efficient tracking, reproducibility, and
                                    deployment. The process includes
                                    initializing MLflow, training or loading a
                                    model, and invoking the appropriate{" "}
                                    <code>log_model</code> function for the
                                    framework you're using (e.g., TensorFlow,
                                    PyTorch, XGBoost, Scikit-learn, or ONNX).
                                    You can also log metadata such as input
                                    examples, environment dependencies, and
                                    custom tags to enhance the model's
                                    description. These logged models can later
                                    be deployed, loaded for inference, or shared
                                    across teams seamlessly.
                                </p>
                                <p className="">
                                    Learn more about MLflow Model logging:
                                </p>
                                <ul className="ml-4 list-disc">
                                    <li>
                                        {" "}
                                        <a
                                            href="https://mlflow.org/docs/latest/python_api/mlflow.html#mlflow.log_artifact"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="links !text-[16px]"
                                        >
                                            MLflow log_artifact Documentation
                                        </a>
                                        <li>
                                            <a
                                                href="https://mlflow.org/docs/latest/python_api/mlflow.html#mlflow.autolog"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="links !text-[16px]"
                                            >
                                                MLflow autolog Documentation
                                            </a>
                                        </li>
                                    </li>
                                </ul>
                                <p>
                                    Below are examples of logging models for
                                    different frameworks:
                                </p>
                                <p className="text-md">Examples</p>
                                <ol className="list-decimal p-2 mx-2">
                                    <li className="my-2">
                                        <strong>TensorFlow</strong>
                                        <div className="w-full my-4">
                                            <CopyBlock
                                                text={codeSnippets.tensorflow}
                                                language="python"
                                                showLineNumbers={false}
                                                theme={anOldHope}
                                                wrapLines={true}
                                                codeBlock
                                            />
                                        </div>
                                        <p>
                                            Learn more about MLflow TensorFlow
                                            model logging:
                                        </p>
                                        <ul className="ml-4 list-disc">
                                            <li>
                                                {" "}
                                                <a
                                                    href="https://mlflow.org/docs/latest/python_api/mlflow.tensorflow.html#mlflow.tensorflow.log_model"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="links !text-[16px]"
                                                >
                                                    MLflow TensorFlow log_model
                                                    Documentation
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="my-2">
                                        <strong>PyTorch</strong>
                                        <div className="w-full my-4">
                                            <CopyBlock
                                                text={codeSnippets.pytorch}
                                                language="python"
                                                showLineNumbers={false}
                                                theme={anOldHope}
                                                wrapLines={true}
                                                codeBlock
                                            />
                                        </div>
                                        <p>
                                            Learn more about logging PyTorch
                                            models with MLflow:
                                        </p>
                                        <ul className="ml-4 list-disc">
                                            <li>
                                                {" "}
                                                <a
                                                    href="https://mlflow.org/docs/latest/python_api/mlflow.pytorch.html#mlflow.pytorch.log_model"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="links !text-[16px]"
                                                >
                                                    MLflow PyTorch log_model
                                                    Documentation
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="my-2">
                                        <strong>XGBoost</strong>
                                        <div className="w-full my-4">
                                            <CopyBlock
                                                text={codeSnippets.xgboost}
                                                language="python"
                                                showLineNumbers={false}
                                                theme={anOldHope}
                                                wrapLines={true}
                                                codeBlock
                                            />
                                        </div>
                                        <p>
                                            Learn more about logging XGBoost
                                            models with MLflow:
                                        </p>
                                        <ul className="ml-4 list-disc">
                                            <li>
                                                {" "}
                                                <a
                                                    href="https://mlflow.org/docs/latest/python_api/mlflow.xgboost.html#mlflow.xgboost.log_model"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="links !text-[16px]"
                                                >
                                                    MLflow XGBoost log_model
                                                    Documentation
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="my-2">
                                        <strong>Scikit-learn</strong>
                                        <div className="w-full my-4">
                                            <CopyBlock
                                                text={codeSnippets.sklearn}
                                                language="python"
                                                showLineNumbers={false}
                                                theme={anOldHope}
                                                wrapLines={true}
                                                codeBlock
                                            />
                                        </div>
                                        <p>
                                            Learn more about logging
                                            Scikit-learn models with MLflow:
                                        </p>
                                        <ul className="ml-4 list-disc">
                                            <li>
                                                {" "}
                                                <a
                                                    href="https://mlflow.org/docs/latest/python_api/mlflow.sklearn.html#mlflow.sklearn.log_model"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="links !text-[16px]"
                                                >
                                                    MLflow Scikit-learn
                                                    log_model Documentation
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="my-2">
                                        <strong>ONNX</strong>
                                        <div className="w-full my-4">
                                            <CopyBlock
                                                text={codeSnippets.onnx}
                                                language="python"
                                                showLineNumbers={false}
                                                theme={anOldHope}
                                                wrapLines={true}
                                                codeBlock
                                            />
                                        </div>
                                        <p>
                                            Learn more about logging ONNX models
                                            with MLflow:
                                        </p>
                                        <ul className="ml-4 list-disc">
                                            <li>
                                                {" "}
                                                <a
                                                    href="https://mlflow.org/docs/latest/python_api/mlflow.onnx.html#mlflow.onnx.log_model"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="links !text-[16px]"
                                                >
                                                    MLflow ONNX log_model
                                                    Documentation
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <strong>Retrieve Logged Files</strong>
                                <p className="my-2">
                                    The <code>mlruns</code> folder is
                                    automatically created by MLflow to store
                                    experiment data, models, and metadata,
                                    enabling tracking and reproducibility. It
                                    organizes experiments by{" "}
                                    <strong>experiment IDs</strong> (e.g.,{" "}
                                    <code>0/</code>) and{" "}
                                    <strong>unique run IDs</strong> (
                                    <code>&lt;run-id&gt;</code>). Each run
                                    directory contains an{" "}
                                    <code>artifacts/model/</code> folder with
                                    key files:
                                </p>
                                <ul className="list-decimal ml-4">
                                    <li>
                                        <code>MLmodel</code>: Metadata defining
                                        the model type, framework, and
                                        input/output signatures.
                                    </li>
                                    <li>
                                        {" "}
                                        <code>model.pkl</code>: Serialized model
                                        file (e.g., Pickle for Scikit-learn).
                                    </li>
                                    <li>
                                        <code>requirements.txt</code>: Python
                                        dependencies for running the model.
                                    </li>
                                    <li>
                                        <code>conda.yaml</code> and{" "}
                                        <code>python_env.yaml</code>:
                                        Environment configuration files for
                                        recreating the model's runtime
                                        environment.
                                    </li>
                                </ul>
                                <p className="my-2">
                                    The <code>mlruns</code> folder is essential
                                    for ensuring reproducibility, versioning,
                                    and seamless model deployment. These logged
                                    files support efficient sharing and
                                    deployment across teams and environments.
                                </p>
                                <p className="font-semibold">
                                    Example Directory Structure
                                </p>
                                <div className="">
                                    <p>mlruns/</p>
                                    <p>&emsp;├── 0/</p>
                                    <p>
                                        {" "}
                                        &emsp;&emsp;&emsp; ├── &lt;run-id&gt;/
                                    </p>
                                    <p>
                                        {" "}
                                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                        ├── artifacts/
                                    </p>
                                    <p>
                                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;├──
                                        model/
                                    </p>
                                    <p>
                                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;├──
                                        MLmodel
                                    </p>
                                    <p>
                                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;├──
                                        model.pkl
                                    </p>
                                    <p>
                                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;├──
                                        requirements.txt
                                    </p>
                                    <p>
                                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;├──
                                        conda.yaml
                                    </p>
                                    <p>
                                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;├──
                                        python_env.yaml
                                    </p>
                                    <p>
                                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;└──
                                        (optional files as applicable)
                                    </p>
                                </div>
                                {/* <ul className="pl-4">
                                    <li>
                                        <span className="font-bold">
                                            mlruns/
                                        </span>
                                        <ul className="pl-6 border-l-2 border-gray-300 ml-2">
                                            <li>
                                                <span className="font-bold before:content-['---'] before:text-gray-300">
                                                    0/
                                                </span>
                                                <ul className="pl-6 border-l-2 border-gray-300 ml-2">
                                                    <li>
                                                        <span className="font-bold">
                                                            &lt;run-id&gt;/
                                                        </span>
                                                        <ul className="pl-6 border-l-2 border-gray-300 ml-2">
                                                            <li>
                                                                <span className="font-bold">
                                                                    artifacts/
                                                                </span>
                                                                <ul className="pl-6 border-l-2 border-gray-300 ml-2">
                                                                    <li>
                                                                        <span className="font-bold">
                                                                            model/
                                                                        </span>
                                                                        <ul className="pl-6 border-l-2 border-gray-300 ml-2">
                                                                            <li>
                                                                                MLmodel
                                                                            </li>
                                                                            <li>
                                                                                model.pkl
                                                                            </li>
                                                                            <li>
                                                                                requirements.txt
                                                                            </li>
                                                                            <li>
                                                                                conda.yaml
                                                                            </li>
                                                                            <li>
                                                                                python_env.yaml
                                                                            </li>
                                                                            <li>
                                                                                (optional
                                                                                files
                                                                                as
                                                                                applicable)
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul> */}
                                <p className="my-3">
                                    Learn more at the MLflow Model API
                                    Documentation about{" "}
                                    <a
                                        href="https://mlflow.org/docs/latest/models.html#storage-format"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="links !text-[16px]"
                                    >
                                        MLflow tracking
                                    </a>
                                    .
                                </p>
                            </li>
                            <li>
                                <strong>
                                    Writing Dockerfile and Model Settings for
                                    Deployment
                                </strong>
                                <p className="font-semibold">
                                    Step 1: Download Dockerfile and Model
                                    Settings
                                </p>
                                <p>
                                    Download the required configuration files (
                                    <code>Dockerfile</code> and{" "}
                                    <code>model-settings.json</code>). These
                                    files provide generic runtime settings and
                                    Docker configurations, which you can edit
                                    and customize as needed.
                                </p>
                                <h2 className="font-semibold">
                                    Method 1: Using the Vipas.AI UI
                                </h2>
                                <div className="ml-4">
                                    <ul className="list-disc ">
                                        <li>
                                            Navigate to the{" "}
                                            <a
                                                href="https://vipas.ai/project/create/main"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="links !text-[16px]"
                                            >
                                                Create Model Section
                                            </a>{" "}
                                            by clicking the Create section.
                                        </li>
                                        <li>
                                            While uploading your model files,
                                            you can download the pre-generated{" "}
                                            <code>Dockerfile</code> and{" "}
                                            <code>model-settings.json</code>{" "}
                                            directly from the UI.
                                        </li>
                                        <li>
                                            These files include generic runtime
                                            settings for your model, but you can
                                            edit them if your model requires
                                            additional configurations or custom
                                            logic.
                                        </li>
                                    </ul>
                                    <img
                                        src={defaultRuntime}
                                        loading="lazy"
                                        className="my-6 tw-w-full tw-h-auto tw-object-contain"
                                        alt="default-runtime"
                                    />
                                </div>
                                <p className="font-semibold">
                                    Method 2: Using the Vipas SDK
                                </p>
                                <p>
                                    Use the Vipas SDK to generate the required
                                    files programmatically:
                                </p>
                                <div className="w-full my-4">
                                    <CopyBlock
                                        text={codeSnippets.vipasSDK}
                                        language="python"
                                        showLineNumbers={false}
                                        theme={anOldHope}
                                        wrapLines={true}
                                        codeBlock
                                    />
                                </div>
                                <p className="my-2">
                                    Example Docker file and model-settings.json
                                </p>
                                <div className="w-full my-4">
                                    <CopyBlock
                                        text={codeSnippets.defaultDocker}
                                        language="yaml"
                                        showLineNumbers={false}
                                        theme={anOldHope}
                                        wrapLines={true}
                                        codeBlock
                                    />
                                </div>
                                <div className="w-full my-4">
                                    <CopyBlock
                                        text={codeSnippets.defaultModelSettings}
                                        language="json"
                                        showLineNumbers={false}
                                        theme={anOldHope}
                                        wrapLines={true}
                                        codeBlock
                                    />
                                </div>
                                <p>
                                    This method is ideal for automation and
                                    ensures consistent setups across
                                    deployments.
                                </p>
                                <p className="font-semibold">
                                    Step 2: Edit the Docker file and
                                    model-settings.json for custom runtime
                                    (OPTIONAL)
                                </p>
                                <p>
                                    If your model requires additional processing
                                    logic (e.g., preprocessing or
                                    postprocessing), or if you need to include
                                    additional dependencies, follow these steps:
                                </p>
                                <div className="ml-2">
                                    <ol className="list-decimal ml-2">
                                        <li>
                                            <strong>
                                                Modify the{" "}
                                                <code>model-settings.json</code>{" "}
                                                File:
                                            </strong>
                                            <ul className="list-disc">
                                                <li>
                                                    <strong>
                                                        Update the{" "}
                                                        <code>
                                                            implementation
                                                        </code>{" "}
                                                        key
                                                    </strong>
                                                    : In the{" "}
                                                    <code>
                                                        model-settings.json
                                                    </code>{" "}
                                                    file, set the{" "}
                                                    <code>implementation</code>{" "}
                                                    key to point to your custom
                                                    runtime class. This custom
                                                    runtime class should extend
                                                    the{" "}
                                                    <code>MLflowRuntime</code>{" "}
                                                    class.
                                                </li>
                                                <li>
                                                    <strong>
                                                        Customize the runtime
                                                        class
                                                    </strong>
                                                    : In your custom runtime
                                                    class, override the predict
                                                    function to implement your
                                                    own prediction logic.
                                                </li>
                                                <li>
                                                    <strong>Note</strong>:
                                                    <ol className="list-decimal ml-4">
                                                        <li>
                                                            <strong>
                                                                Ensure class
                                                                name matches
                                                            </strong>
                                                            : The class name
                                                            specified in the{" "}
                                                            <code>
                                                                implementation
                                                            </code>{" "}
                                                            key must match the
                                                            name of the class
                                                            you define.
                                                            Mismatched class
                                                            names will result in
                                                            runtime errors.
                                                        </li>
                                                        <li>
                                                            <strong>
                                                                Optional step
                                                                for custom
                                                                runtime
                                                            </strong>
                                                            :This step is
                                                            required only if you
                                                            want to define a
                                                            custom runtime. If
                                                            you do not require
                                                            custom prediction
                                                            logic, you can skip
                                                            this step and use
                                                            the default runtime
                                                            provided. For more
                                                            information on
                                                            custom runtime,
                                                            please visit the
                                                            MLserver
                                                            Documentation about{" "}
                                                            <a
                                                                href="https://mlserver.readthedocs.io/en/latest/user-guide/custom.html#user-guide-custom--page-root"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="links !text-[16px]"
                                                            >
                                                                custom inference
                                                                runtimes
                                                            </a>{" "}
                                                            and{" "}
                                                            <a
                                                                href="https://mlserver.readthedocs.io/en/latest/reference/model-settings.html"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="links !text-[16px]"
                                                            >
                                                                model-settings.json
                                                            </a>
                                                            file.
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ul>
                                            <p>Example:</p>
                                            <div className="w-full my-4">
                                                <CopyBlock
                                                    text={
                                                        codeSnippets.customRuntimeModelSettings
                                                    }
                                                    language="json"
                                                    showLineNumbers={false}
                                                    theme={anOldHope}
                                                    wrapLines={true}
                                                    codeBlock
                                                />
                                            </div>
                                            <p>
                                                Example for ‘
                                                <strong>
                                                    custom_runtime.py
                                                </strong>
                                                ’
                                            </p>
                                            <div className="w-full my-4">
                                                <CopyBlock
                                                    text={
                                                        codeSnippets.customRuntime
                                                    }
                                                    language="python"
                                                    showLineNumbers={false}
                                                    theme={anOldHope}
                                                    wrapLines={true}
                                                    codeBlock
                                                />
                                            </div>
                                        </li>
                                        <li>
                                            <strong>
                                                Edit the Dockerfile for
                                                Processor Files
                                            </strong>
                                            :
                                            <ul className="list-disc ml-4">
                                                <li>
                                                    If your custom logic
                                                    requires additional
                                                    processor files (e.g., .py
                                                    files for preprocessing or
                                                    postprocessing), update the
                                                    Dockerfile to copy these
                                                    files into the Docker image.
                                                </li>
                                            </ul>
                                            <div className="w-full my-4">
                                                <CopyBlock
                                                    text={
                                                        codeSnippets.customRuntimeDocker
                                                    }
                                                    language="shell"
                                                    showLineNumbers={false}
                                                    theme={anOldHope}
                                                    wrapLines={true}
                                                    codeBlock
                                                />
                                            </div>
                                            <div className="w-full my-4">
                                                <CopyBlock
                                                    text={
                                                        codeSnippets.customRuntimeModelSettings
                                                    }
                                                    language="json"
                                                    showLineNumbers={false}
                                                    theme={anOldHope}
                                                    wrapLines={true}
                                                    codeBlock
                                                />
                                            </div>
                                        </li>
                                        <li>
                                            <strong>
                                                Install Additional Dependencies
                                            </strong>
                                            :
                                            <ul className="list-disc ml-4">
                                                <li>
                                                    If your model requires extra
                                                    packages, update the{" "}
                                                    <code>Dockerfile</code> to
                                                    include these dependencies.
                                                </li>
                                                <li>
                                                    Add them to the{" "}
                                                    <code>RUN</code> section:
                                                </li>
                                            </ul>
                                            <div className="w-full my-4">
                                                <CopyBlock
                                                    text={
                                                        codeSnippets.packageInstallCommand
                                                    }
                                                    language="shell"
                                                    showLineNumbers={false}
                                                    theme={anOldHope}
                                                    wrapLines={true}
                                                    codeBlock
                                                />
                                            </div>
                                            <img
                                                src={customRuntime}
                                                loading="lazy"
                                                className="my-6 tw-w-full tw-h-auto tw-object-contain"
                                                alt="custom-runtime"
                                            />
                                        </li>
                                    </ol>
                                </div>
                                <p className="font-semibold">
                                    Step 3: Build the Docker Image
                                </p>
                                <p>
                                    After downloading the{" "}
                                    <code>Dockerfile</code> and{" "}
                                    <code>model-settings.json</code>:
                                </p>
                                <ol className="ml-4 list-decimal">
                                    <li>
                                        <strong>Update Dependencies</strong>:
                                        <ul className="list-disc ml-2">
                                            <li>
                                                Ensure all dependencies recorded
                                                by MLflow tracking are
                                                installed.
                                            </li>
                                            <li>
                                                If your model requires
                                                additional packages or files for
                                                prediction, include them in the
                                                Dockerfile.
                                            </li>
                                            <li>
                                                Modify the{" "}
                                                <code>model-settings.json</code>{" "}
                                                file if you need to override the
                                                default MLflow runtime logic or
                                                implement custom prediction
                                                flow.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Build the Docker Image</strong>:
                                        <ul className="list-disc ml-2">
                                            <li>
                                                Use the <code>Dockerfile</code>{" "}
                                                to build the Docker image:
                                            </li>
                                        </ul>
                                        <div className="w-full my-4">
                                            <CopyBlock
                                                text={codeSnippets.dockerBuild}
                                                language="shell"
                                                showLineNumbers={false}
                                                theme={anOldHope}
                                                wrapLines={true}
                                                codeBlock
                                            />
                                        </div>
                                        <p className="font-semibold">
                                            Step 4: Stage or Publish Your Model
                                        </p>
                                        <p>
                                            Once your Docker image is built, the
                                            first step is to push it to Docker
                                            Hub .Use the following command to
                                            upload your image:
                                        </p>
                                        <div className="w-full my-4">
                                            <CopyBlock
                                                text={codeSnippets.dockerPush}
                                                language="shell"
                                                showLineNumbers={false}
                                                theme={anOldHope}
                                                wrapLines={true}
                                                codeBlock
                                            />
                                        </div>
                                        <p>
                                            After successfully pushing the
                                            image, you can choose one of the
                                            following methods to stage or
                                            publish your model on Vipas.AI:
                                        </p>
                                        <p>
                                            <strong>
                                                Option 1: Stage the Model Using
                                                the Vipas.AI UI
                                            </strong>
                                        </p>
                                        <ol className="list-decimal ml-6">
                                            <li>
                                                Navigate to the Vipas.AI UI.
                                            </li>
                                            <li>
                                                Stage your model by providing
                                                the Docker image details,
                                                including the image name and
                                                tag.
                                            </li>
                                        </ol>
                                        <img
                                            src={stageMlflowModel}
                                            loading="lazy"
                                            className="my-6 tw-w-full tw-h-auto tw-object-contain"
                                            alt="stage-mlflow-model"
                                        />
                                        <p>
                                            <strong>
                                                Option 2: Publish the Model
                                                Programmatically Using the Vipas
                                                SDK
                                            </strong>
                                        </p>
                                        <p>
                                            Use the Vipas SDK to publish the
                                            model programmatically with the
                                            following Python script:
                                        </p>
                                        <div className="w-full my-4">
                                            <CopyBlock
                                                text={codeSnippets.vipasPublish}
                                                language="python"
                                                showLineNumbers={false}
                                                theme={anOldHope}
                                                wrapLines={true}
                                                codeBlock
                                            />
                                        </div>
                                        <p>
                                            Ensure the JSON file referenced in
                                            the <code>mlflow_config_path</code>{" "}
                                            contains the following keys:
                                        </p>
                                        <div className="w-full my-4">
                                            <CopyBlock
                                                text={
                                                    codeSnippets.mlflowConfigPath
                                                }
                                                language="json"
                                                showLineNumbers={false}
                                                theme={anOldHope}
                                                wrapLines={true}
                                                codeBlock
                                            />
                                        </div>
                                        <p>
                                            By following these steps, your model
                                            will be staged or published on
                                            Vipas.AI, ready for launch and
                                            usage.
                                        </p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        {/* <div className='w-full my-4'>
             <CopyBlock
               text={codeSnippets.serializeModel}
               language="python"
               showLineNumbers={false}
               theme={anOldHope}
               wrapLines={true}
               codeBlock
             />
           </div>
           <p className="my-2" >
             Ensure that the .joblib file includes all necessary dependencies for the model to run. Upload this single .joblib file to the Vipas.AI platform for deployment.
           </p> */}
                    </div>

                    <div className="my-6" id="summary-workflow">
                        <h2 className="text-xl font-semibold">
                            Summary Workflow
                        </h2>
                        <ol className="list-decimal ml-4">
                            <li>
                                <strong>Download Configuration Files</strong>:
                                Use the Vipas.AI UI or SDK to obtain the{" "}
                                <code>Dockerfile</code> and{" "}
                                <code>model-settings.json</code>.
                            </li>
                            <li>
                                <strong>Edit and Build</strong>: Update the
                                configuration files if necessary and build the
                                Docker image.
                            </li>
                            <li>
                                <strong>Stage or Publish</strong>: Upload the
                                Docker image to a registry and either stage the
                                model via the UI or publish it programmatically
                                with the SDK.
                            </li>
                        </ol>
                        <p>
                            By following this workflow, you ensure your model is
                            properly packaged, meets all dependency
                            requirements, and is ready for deployment.
                        </p>
                    </div>

                    <div className="my-6" id="examples">
                        <h2 className="sub-heading">Examples</h2>
                        <CollapsibleSection
                            title={"Deploying a PyTorch model on Vipas.AI"}
                        >
                            <div className="border rounded-[5px] w-[250px] p-2 shadow-md my-2 hover:bg-gray-100">
                                <a
                                    href="https://utils.vipas.ai/vps-ipynb/ml_flow_pytorch/pytorch.zip"
                                    target="_blank"
                                    className="ml-3 flex items-center"
                                >
                                    <img
                                        src={downloadIcon}
                                        width={32}
                                        height={32}
                                        alt="text-summarization-download"
                                    />{" "}
                                    <span className="ml-2">
                                        Download Notebook
                                    </span>
                                </a>
                            </div>
                            <p>
                                This guide provides a step-by-step explanation
                                of implementing a simple neural network using
                                PyTorch, training it on synthetic data, logging
                                it with MLflow, and deploying it with custom
                                configurations.
                            </p>

                            <p className="text-xl font-semibold my-2">
                                Key Steps:
                            </p>
                            <ol className="list-decimal ml-6">
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Install Required Libraries
                                    </h2>
                                    <p>
                                        Essential libraries like PyTorch,
                                        MLflow, Docker, and Scikit-learn are
                                        used to develop, log, and deploy the
                                        model seamlessly.
                                    </p>
                                </li>

                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Define the PyTorch Model
                                    </h2>
                                    <p>
                                        A basic neural network is built using
                                        PyTorch. The SimpleNN class defines an
                                        architecture with:
                                    </p>
                                    <ul className="list-disc list-inside ">
                                        <li>An input layer.</li>
                                        <li>
                                            A hidden layer with ReLU activation.
                                        </li>
                                        <li>
                                            An output layer for binary
                                            classification.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Generate Synthetic Data
                                    </h2>
                                    <p>
                                        Synthetic data is created with 1,000
                                        samples and 10 features. Labels are
                                        binary (0 or 1). The dataset is split
                                        into training (80%) and testing (20%)
                                        subsets.
                                    </p>
                                </li>

                                <li className="mb-6">
                                    <h2 className="font-semibold mb-2">
                                        Data Preprocessing
                                    </h2>
                                    <p>
                                        The data is converted into PyTorch
                                        tensors, enabling compatibility with
                                        PyTorch's data pipeline.
                                    </p>
                                </li>

                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Model Training
                                    </h2>
                                    <p>
                                        The model is trained over 50 epochs
                                        using the Adam optimizer and
                                        cross-entropy loss. Loss values are
                                        printed at every 10th epoch.
                                    </p>
                                </li>

                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Evaluate Model Performance
                                    </h2>
                                    <p>
                                        Accuracy is calculated on the test
                                        dataset using predictions generated by
                                        the trained model.
                                    </p>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Log Model with MLflow
                                    </h2>
                                    <p>
                                        The trained model and its parameters are
                                        logged into MLflow. This includes:
                                    </p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            Logging model architecture,
                                            parameters, and accuracy.
                                        </li>
                                        <li>
                                            Storing the trained model for later
                                            use.
                                        </li>
                                        <li>
                                            Capturing the unique run_id for
                                            deployment.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Create Deployment Configuration
                                    </h2>
                                    <p>
                                        A deployment-ready configuration is
                                        generated:
                                    </p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>Model Settings File</strong>
                                            : Defines the runtime and URI for
                                            MLServer.
                                        </li>
                                        <li>
                                            <strong>Dockerfile</strong>:
                                            Specifies the environment and
                                            dependencies for containerizing the
                                            model.{" "}
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Build and Push Docker Image
                                    </h2>
                                    <p>Using Docker:</p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            The model is containerized with the
                                            created configuration.
                                        </li>
                                        <li>
                                            Optionally, the image can be pushed
                                            to a container registry for external
                                            deployment.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Publish Model to Vipas.AI
                                    </h2>
                                    <p>Using Vipas.AI SDK:</p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            The model is published with metadata
                                            such as permissions and the Docker
                                            image tag.
                                        </li>
                                        <li>
                                            Publishing status and responses are
                                            logged.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Run Predictions
                                    </h2>
                                    <p>
                                        A JSON input structure is defined, and
                                        the deployed model predicts the output
                                        for the given data:
                                    </p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>Input</strong>: A JSON
                                            object containing features.
                                        </li>
                                        <li>
                                            <strong>Output</strong>: The
                                            predicted class is displayed in a
                                            user-friendly widget interface.
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </CollapsibleSection>

                       <CollapsibleSection title={"Wine Quality Prediction Using ElasticNet Regression with MLflow"}>

                            <div className="border rounded-[5px] w-[250px] p-2 shadow-md my-2 hover:bg-gray-100">
                                <a
                                    href="https://utils.vipas.ai/vps-ipynb/ml_flow_sklearn/sklearn.zip"
                                    target="_blank"
                                    className="ml-3 flex items-center"
                                >
                                    <img
                                        src={downloadIcon}
                                        width={32}
                                        height={32}
                                        alt="text-summarization-download"
                                    />{" "}
                                    <span className="ml-2">
                                        Download Notebook
                                    </span>
                                </a>
                            </div>
                            <p className="my-2">
                                This guide explains the implementation of a
                                machine learning pipeline to predict wine
                                quality based on various physicochemical
                                attributes. The process involves data
                                preprocessing, model training, evaluation, and
                                deployment with ElasticNet regression using
                                MLflow for tracking and logging.
                            </p>

                            <p className="text-xl font-semibold my-2">
                                Key Steps:
                            </p>
                            <ol className="list-decimal ml-6">
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Dataset Overview
                                    </h2>
                                    <p>
                                        The{" "}
                                        <strong>Wine Quality Dataset</strong>{" "}
                                        from the UCI repository is used. This
                                        dataset contains physicochemical
                                        properties (e.g., acidity, sugar, pH) as
                                        features and wine quality scores
                                        (ranging from 3 to 9) as the target
                                        variable.
                                    </p>
                                </li>

                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Data Preprocessing
                                    </h2>
                                    <ul className="list-disc list-inside ">
                                        <li>
                                            <strong>Splitting Data</strong>: The
                                            dataset is split into training (75%)
                                            and testing (25%) subsets.
                                        </li>
                                        <li>
                                            <strong>
                                                Feature and Target Separation
                                            </strong>
                                            : Features are extracted by dropping
                                            the <code>quality</code> column,
                                            while the target variable (
                                            <code>quality</code>) is used for
                                            predictions.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Model Selection: ElasticNet Regression
                                    </h2>
                                    <p>
                                        The{" "}
                                        <code>ElasticNet regression model</code>{" "}
                                        is used, which combines L1 (Lasso) and
                                        L2 (Ridge) regularization:
                                    </p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            <code>alpha</code>: Controls the
                                            regularization strength.
                                        </li>
                                        <li>
                                            <code>l1_ratio</code>: Balances L1
                                            and L2 regularization.
                                        </li>
                                    </ul>
                                </li>

                                <li className="mb-6">
                                    <h2 className="font-semibold mb-2">
                                        Model Training
                                    </h2>
                                    <p>
                                        The ElasticNet model is trained on the
                                        training data using the default
                                        parameters:
                                    </p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            <code>alpha=0.5</code>
                                        </li>
                                        <li>
                                            <code>l1_ratio=0.5</code>
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Model Evaluation
                                    </h2>
                                    <p>
                                        Key evaluation metrics calculated on the
                                        test dataset include:
                                    </p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>
                                                Root Mean Squared Error (RMSE)
                                            </strong>
                                            : Measures prediction error.
                                        </li>
                                        <li>
                                            <strong>
                                                Mean Absolute Error (MAE)
                                            </strong>
                                            : Measures average magnitude of
                                            errors.
                                        </li>
                                        <li>
                                            <strong>R-squared (R²)</strong>:
                                            Explains variance captured by the
                                            model.
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Logging with MLflow
                                    </h2>
                                    <p>MLflow is used to log the following:</p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>Parameters</strong>:{" "}
                                            <code>alpha</code> and{" "}
                                            <code>l1_ratio.</code>
                                        </li>
                                        <li>
                                            <strong>Metrics</strong>: RMSE, MAE,
                                            and R² scores.
                                        </li>
                                        <li>
                                            <strong>Model</strong>: The trained
                                            ElasticNet model is logged and
                                            stored.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Deployment Configuration
                                    </h2>
                                    <p>
                                        After training, the model is prepared
                                        for deployment using:
                                    </p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>
                                                MLServer Configuration
                                            </strong>
                                            : Defines the model runtime and
                                            parameters.
                                        </li>
                                        <li>
                                            <strong>Dockerfile</strong>: Creates
                                            a Docker image for deploying the
                                            model with MLServer.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Prediction
                                    </h2>
                                    <p>
                                        The model takes physicochemical
                                        attributes as inputs to predict wine
                                        quality scores. For example:
                                    </p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            Inputs include features like{" "}
                                            <code>fixed acidity</code>,{" "}
                                            <code>volatile acidity</code>,{" "}
                                            <code>citric acid</code>, etc.
                                        </li>
                                        <li>
                                            The model outputs a predicted
                                            quality score.
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                            <p className="font-semibold">Summary</p>
                            <p>
                                This implementation demonstrates how to build a
                                regression model for predicting wine quality,
                                focusing on feature engineering, model
                                evaluation, and logging for reproducibility. The
                                pipeline integrates ElasticNet regression and
                                MLflow for efficient experimentation and
                                deployment on Vipas.AI.
                            </p>
                            </CollapsibleSection>
                            <CollapsibleSection title={"TensorFlow Model Training and Deployment with MLflow"}>
                            <div className="border rounded-[5px] w-[250px] p-2 shadow-md my-2 hover:bg-gray-100">
                                <a
                                    href="https://utils.vipas.ai/vps-ipynb/ml_flow_tensorflow/tensor_flow.zip"
                                    target="_blank"
                                    className="ml-3 flex items-center"
                                >
                                    <img
                                        src={downloadIcon}
                                        width={32}
                                        height={32}
                                        alt="text-summarization-download"
                                    />{" "}
                                    <span className="ml-2">
                                        Download Notebook
                                    </span>
                                </a>
                            </div>
                            <p className="my-2">
                                This guide demonstrates the workflow for
                                building, training, and deploying a simple
                                TensorFlow model using MLflow for tracking and
                                logging. The model predicts scalar values based
                                on input features using a fully connected neural
                                network architecture.
                            </p>

                            <p className="text-xl font-semibold my-2">
                                Key Steps:
                            </p>
                            <ol className="list-decimal ml-6">
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Dataset Generation
                                    </h2>
                                    <p>Synthetic data is generated with:</p>
                                    <ul className="list-decimal list-inside">
                                        <li>
                                            100 samples, each containing 10
                                            features (<code>X_train</code>).
                                        </li>
                                        <li>
                                            Corresponding target values (
                                            <code>y_train</code>).
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Model Definition
                                    </h2>
                                    <p>
                                        A sequential model is defined using
                                        TensorFlow's Keras API:
                                    </p>
                                    <ul className="list-disc list-inside ">
                                        <li>
                                            <strong>Input Layer</strong>: 10
                                            features (based on input shape).
                                        </li>
                                        <li>
                                            <strong>Hidden Layer</strong>: A
                                            dense layer with 64 neurons and ReLU
                                            activation.
                                        </li>
                                        <li>
                                            <strong>Output Layer</strong>: A
                                            single neuron with a linear
                                            activation function for regression
                                            tasks.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Model Compilation
                                    </h2>

                                    <ul className="list-disc list-inside">
                                        <li>
                                            Optimizer: <code>Adam</code> for
                                            efficient weight updates.
                                        </li>
                                        <li>
                                            Loss Function:{" "}
                                            <code>
                                                Mean Squared Error (MSE)
                                            </code>{" "}
                                            to minimize the difference between
                                            predicted and actual values.
                                        </li>
                                    </ul>
                                </li>

                                <li className="mb-6">
                                    <h2 className="font-semibold mb-2">
                                        Model Training
                                    </h2>
                                    <p>
                                        The model is trained for 5 epochs with a
                                        batch size of 16, ensuring quick
                                        experimentation.
                                    </p>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        MLflow Tracking
                                    </h2>
                                    <p>
                                        MLflow's TensorFlow autologging is
                                        enabled to automatically track:
                                    </p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            Model architecture and
                                            configuration.
                                        </li>
                                        <li>
                                            Training parameters (e.g., epochs,
                                            batch size).
                                        </li>
                                        <li>
                                            Metrics such as loss values over
                                            epochs.
                                        </li>
                                    </ul>
                                    <p>
                                        The trained model is saved as an H5 file
                                        (<code>my_model.h5</code>) and logged to
                                        MLflow for further use.
                                    </p>
                                </li>

                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Run ID
                                    </h2>
                                    <p>
                                        A unique <code>run_id</code> is
                                        generated for each training session,
                                        enabling tracking and reproducibility.
                                    </p>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Deployment Preparation
                                    </h2>
                                    <p>
                                        The saved model and its configurations
                                        are prepared for deployment:
                                    </p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>
                                                MLServer Configuration
                                            </strong>
                                            : Specifies the runtime (
                                            <code>
                                                mlserver_mlflow.MLflowRuntime
                                            </code>
                                            ) and model URI.
                                        </li>
                                        <li>
                                            <strong>Dockerfile</strong>: Creates
                                            a containerized environment for the
                                            model with dependencies like
                                            TensorFlow and MLServer.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Docker Image Creation and Publishing
                                    </h2>
                                    <p>
                                        The model is containerized using Docker:
                                    </p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            The saved model, configuration
                                            files, and dependencies are packaged
                                            into a Docker image.
                                        </li>
                                        <li>
                                            The image is optionally pushed to a
                                            Docker registry for remote
                                            deployment.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Model Prediction
                                    </h2>
                                    <p>
                                        After deployment, the model can accept
                                        JSON inputs for prediction. For example:
                                    </p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>Input JSON</strong>:
                                            Contains an array of 10 features for
                                            inference.
                                        </li>
                                        <li>
                                            <strong>Output</strong>: The model
                                            predicts a scalar value based on the
                                            input.
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                            <p className="font-semibold">Summary</p>
                            <p>
                                This workflow demonstrates how to build a robust
                                pipeline for training, tracking, and deploying
                                TensorFlow models using MLflow. It highlights
                                best practices for versioning, reproducibility,
                                and scaling machine learning workflows in
                                production. The modular approach ensures
                                seamless integration with tools like Docker and
                                MLServer for efficient deployment on Vipas.AI.
                            </p>
                            </CollapsibleSection>

                            <CollapsibleSection title={"Movie Recommendation System Training and Deployment with MLflow"}>
                                <div className="border rounded-[5px] w-[250px] p-2 shadow-md my-2 hover:bg-gray-100">
                                <a
                                    href="https://utils.vipas.ai/vps-ipynb/ml_flow_tensorflow/movie_recomendation_system.zip"
                                    target="_blank"
                                    className="ml-3 flex items-center"
                                >
                                    <img
                                        src={downloadIcon}
                                        width={32}
                                        height={32}
                                        alt="text-summarization-download"
                                    />{" "}
                                    <span className="ml-2">
                                        Download Notebook
                                    </span>
                                </a>
                            </div>
                            <p className="my-2">
                                This guide provides a comprehensive workflow for
                                training, logging, and deploying a
                                TensorFlow-based recommendation model using
                                MLflow, Docker, and the Vipas SDK. The model
                                predicts movie ratings based on user and movie
                                IDs, leveraging embedding layers for feature
                                extraction and dense layers for regression.
                            </p>

                            <p className="text-xl font-semibold my-2">
                                Key Steps:
                            </p>
                            <ol className="list-decimal ml-6">
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Dataset Download and Loading
                                    </h2>
                                    <ul className="list-disc list-inside ">
                                        <li>
                                            <strong>Dataset Source</strong>:{" "}
                                            <a
                                                href="https://mlflow.org/docs/latest/models.html"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="links !text-[16px]"
                                            >
                                                GroupLens MovieLens Dataset
                                            </a>
                                        </li>
                                        <li>
                                            <strong>Description</strong>:
                                            Ratings file (
                                            <code>ratings.csv</code>) is used,
                                            containing:
                                            <ul className="list-disc list-inside">
                                                <li>
                                                    <code>userId</code>
                                                </li>
                                                <li>
                                                    <code>movieId</code>
                                                </li>
                                                <li>
                                                    <code>rating</code>
                                                </li>
                                                <li>
                                                    <code>timestamp</code>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Steps</strong>:
                                            <ul className="list-disc list-inside">
                                                <li>
                                                    Download and extract the
                                                    dataset.
                                                </li>
                                                <li>
                                                    Load{" "}
                                                    <code>ratings.csv</code>{" "}
                                                    into a pandas DataFrame.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Data Preprocessing
                                    </h2>
                                    <ul className="list-disc list-inside ">
                                        <li>
                                            <strong>Description</strong>:
                                            <ul className="list-decimal list-inside">
                                                <li>
                                                    Filter to include the first
                                                    100 unique movie IDs.
                                                </li>
                                                <li>
                                                    Encode <code>userId</code>{" "}
                                                    and <code>movieId</code> as
                                                    integers for model
                                                    compatibility.
                                                </li>
                                                <li>
                                                    Preprocess ratings to
                                                    normalize input.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Libraries Used</strong>:{" "}
                                            <code>pandas</code>,{" "}
                                            <code>LabelEncoder</code> from{" "}
                                            <code>sklearn</code>,{" "}
                                            <code>numpy</code>.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Model Definition
                                    </h2>

                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>Architecture</strong>:
                                            <ul className="list-disc list-inside">
                                                <li>
                                                    <strong>Inputs</strong>:
                                                    <ul className="list-disc list-inside">
                                                        <li>User ID</li>
                                                        <li>Movie ID</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <strong>Layers</strong>:
                                                    <ul className="list-disc list-inside">
                                                        <li>
                                                            Embedding layers for
                                                            users and movies.
                                                        </li>
                                                        <li>
                                                            Flatten and
                                                            concatenate
                                                            embeddings.
                                                        </li>
                                                        <li>
                                                            Fully connected
                                                            layers with ReLU
                                                            activation.
                                                        </li>
                                                        <li>
                                                            Output layer with a
                                                            linear activation
                                                            function for
                                                            regression.
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <strong>Parameters</strong>:
                                                    <ul className="list-disc list-inside">
                                                        <li>
                                                            <strong>
                                                                Embedding
                                                                Dimension
                                                            </strong>
                                                            :User-defined.
                                                        </li>
                                                        <li>
                                                            <strong>
                                                                Learning Rate
                                                            </strong>
                                                            : User-defined.
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Framework</strong>:
                                            TensorFlow with Keras API.
                                        </li>
                                    </ul>
                                </li>

                                <li className="mb-6">
                                    <h2 className="font-semibold mb-2">
                                        Model Training and Logging with MLflow
                                    </h2>

                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>Steps</strong>:
                                            <ol className="list-decimal list-inside">
                                                <li>
                                                    Split data into training and
                                                    testing sets using{" "}
                                                    <code>
                                                        train_test_split
                                                    </code>
                                                    .
                                                </li>
                                                <li>
                                                    Enable MLflow autologging
                                                    with{" "}
                                                    <code>
                                                        mlflow.keras.autolog()
                                                    </code>
                                                    .
                                                </li>
                                                <li>
                                                    Train the model for
                                                    user-defined epochs and
                                                    batch size.
                                                </li>
                                                <li>
                                                    Save the trained model (
                                                    <code>model.h5</code>) and
                                                    log it to MLflow.
                                                </li>
                                                <li>
                                                    Log additional artifacts:
                                                    <ul className="list-disc list-inside">
                                                        <li>
                                                            User and movie
                                                            encoders.
                                                        </li>
                                                        <li>
                                                            Movie ID to title
                                                            mapping.
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <strong>Artifacts Directory</strong>
                                            :{" "}
                                            <code>
                                                mlruns/0/&lt;run_id&gt;/artifacts/
                                            </code>
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Deployment Configuration
                                    </h2>

                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>Steps</strong>:{" "}
                                            <ol className="list-decimal list-inside">
                                                <li>
                                                    Generate MLServer
                                                    configuration using Vipas
                                                    SDK:
                                                    <ul className="list-disc list-inside">
                                                        <li>
                                                            Fetch runtime and
                                                            deployment files.
                                                        </li>
                                                        <li>
                                                            Include necessary
                                                            artifacts like
                                                            encoders and mapping
                                                            files.
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    Store configuration in{" "}
                                                    <code>
                                                        mlflow_config.json
                                                    </code>
                                                    .
                                                </li>
                                            </ol>
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Docker Image Creation and Publishing
                                    </h2>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>Description</strong>:{" "}
                                            Containerize the model for scalable
                                            deployment.
                                        </li>
                                        <li>
                                            <strong>Steps</strong>:
                                            <ul className="list-decimal list-inside">
                                                <li>
                                                    Use the artifacts folder to
                                                    build a Docker image.
                                                </li>
                                                <li>
                                                    Push the image to a Docker
                                                    registry (optional).
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Tools</strong>: Python{" "}
                                            <code>docker</code> library.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Model Publishing with Vipas SDK
                                    </h2>

                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>Description</strong>:
                                            Publish the MLflow model to the
                                            Vipas.AI platform.
                                        </li>
                                        <li>
                                            <strong>Steps</strong>:
                                            <ul className="list-decimal list-inside">
                                                <li>
                                                    Use{" "}
                                                    <code>
                                                        mlflow_config.json
                                                    </code>{" "}
                                                    for deployment
                                                    configurations.
                                                </li>
                                                <li>
                                                    Publish the model using the
                                                    Vipas SDK's{" "}
                                                    <code>publish</code>{" "}
                                                    function.
                                                </li>
                                                <li>
                                                    Retrieve and log the
                                                    response for successful
                                                    deployment.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Model Prediction
                                    </h2>

                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>Workflow</strong>
                                            <ul className="list-decimal list-inside">
                                                <li>
                                                    Accept JSON input containing
                                                    user and movie IDs.
                                                </li>
                                                <li>
                                                    Perform inference using the
                                                    deployed model.
                                                </li>
                                                <li>
                                                    Return predicted ratings as
                                                    output.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                            <p className="font-semibold">Example Input JSON</p>
                            <div className="w-full my-4">
                                <CopyBlock
                                    text={codeSnippets.movieRecommendInput}
                                    language="json"
                                    showLineNumbers={false}
                                    theme={anOldHope}
                                    wrapLines={true}
                                    codeBlock
                                />
                            </div>
                            <p className="font-semibold">Summary</p>
                            <p>
                                This documentation outlines a systematic
                                approach to:
                            </p>
                            <ul className="my-2 list-decimal list-inside">
                                <li>Load and preprocess a dataset.</li>
                                <li>
                                    Define and train a recommendation model
                                    using TensorFlow.
                                </li>
                                <li>
                                    Track and log the training process with
                                    MLflow.
                                </li>
                                <li>
                                    Deploy the model using Docker and Vipas SDK.
                                </li>
                                <li>
                                    Provide an API endpoint for real-time
                                    predictions.
                                </li>
                            </ul>
                            <p>
                                This modular pipeline ensures high scalability
                                and ease of integration for real-world
                                applications.
                            </p>
                            </CollapsibleSection>
                    </div>
                    <Navigation
                        previous={"Previous: Steps to Create a Model"}
                        previousNav={"steps-to-create-a-model"}
                        next={"Next: TensorFlow"}
                        nextNav={"tensorflow-model"}
                    />
                </div>
                <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16">
                    <p className="font-semibold my-8 ">On this page</p>
                    <ul className="border-t-2 py-4">
                        <li className="link-list ">
                            <a href="#supported-file-types" className="links">
                                Supported File Types
                            </a>
                        </li>
                        <li className="link-list ">
                            <a href="#directory-structure" className="links">
                                Directory Structure
                            </a>
                        </li>
                        <li className="link-list ">
                            <a href="#model-configuration" className="links">
                                Model Configuration
                            </a>
                        </li>
                        <li className="link-list ">
                            <a href="#summary-workflow" className="links">
                                Summary Workflow
                            </a>
                        </li>
                        <li className="link-list ">
                            <a href="#examples" className="links">
                                Examples
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default MlflowModel;
