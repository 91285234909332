import React, { useState } from 'react'
import upIcon from "../assets/images/up_icon.svg";
import downIcon from "../assets/images/down_icon.svg";
function CollapsibleSection ({ title, children }) {
    const [isCollapsed, setIsCollapsed] = useState(true);

    return (
        <div className="my-4 ">
            <div className="flex justify-between items-center border px-2 rounded-[10px] bg-gray-100 hover:bg-gray-200">
                <p className="my-2 example-heading">
                    {title}
                </p>
                <span onClick={() => setIsCollapsed(!isCollapsed)} className="hover:cursor-pointer border p-2 rounded-[10px] hover:border-blue-300">
                    <img
                        src={isCollapsed ? downIcon : upIcon}
                        alt="expand-collapse-icon"
                        className="w-[15px] h-[10px]"
                    />
                </span>
            </div>
            {!isCollapsed && <div className="">{children}</div>}
        </div>
    );
};

export default CollapsibleSection
