import React from 'react'
import downloadIcon from "../../../assets/images/download.png";
import Navigation from '../../Navigation';

function TensorflowModelTraining() {
  return (
    <>
    <div className="flex my-8 relative">
               <div className="w-[100%] xl:w-[66.66%] px-[24px]">
                   <h1 className="mb-3 heading">TensorFlow Model Training and Deployment</h1>
                   <div className="border rounded-[5px] w-[250px] p-2 shadow-md my-2 hover:bg-gray-100">
                                <a
                                    href="https://utils.vipas.ai/vps-ipynb/ml_flow_tensorflow/tensor_flow.zip"
                                    target="_blank"
                                    className="ml-3 flex items-center"
                                >
                                    <img
                                        src={downloadIcon}
                                        width={32}
                                        height={32}
                                        alt="text-summarization-download"
                                    />{" "}
                                    <span className="ml-2">
                                        Download Notebook
                                    </span>
                                </a>
                            </div>
                            <p className="my-2">
                                This guide demonstrates the workflow for
                                building, training, and deploying a simple
                                TensorFlow model using MLflow for tracking and
                                logging. The model predicts scalar values based
                                on input features using a fully connected neural
                                network architecture.
                            </p>

                            <p className="text-xl font-semibold my-2">
                                Key Steps:
                            </p>
                            <ol className="list-decimal ml-6">
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Dataset Generation
                                    </h2>
                                    <p>Synthetic data is generated with:</p>
                                    <ul className="list-decimal list-inside">
                                        <li>
                                            100 samples, each containing 10
                                            features (<code>X_train</code>).
                                        </li>
                                        <li>
                                            Corresponding target values (
                                            <code>y_train</code>).
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Model Definition
                                    </h2>
                                    <p>
                                        A sequential model is defined using
                                        TensorFlow's Keras API:
                                    </p>
                                    <ul className="list-disc list-inside ">
                                        <li>
                                            <strong>Input Layer</strong>: 10
                                            features (based on input shape).
                                        </li>
                                        <li>
                                            <strong>Hidden Layer</strong>: A
                                            dense layer with 64 neurons and ReLU
                                            activation.
                                        </li>
                                        <li>
                                            <strong>Output Layer</strong>: A
                                            single neuron with a linear
                                            activation function for regression
                                            tasks.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Model Compilation
                                    </h2>

                                    <ul className="list-disc list-inside">
                                        <li>
                                            Optimizer: <code>Adam</code> for
                                            efficient weight updates.
                                        </li>
                                        <li>
                                            Loss Function:{" "}
                                            <code>
                                                Mean Squared Error (MSE)
                                            </code>{" "}
                                            to minimize the difference between
                                            predicted and actual values.
                                        </li>
                                    </ul>
                                </li>

                                <li className="mb-6">
                                    <h2 className="font-semibold mb-2">
                                        Model Training
                                    </h2>
                                    <p>
                                        The model is trained for 5 epochs with a
                                        batch size of 16, ensuring quick
                                        experimentation.
                                    </p>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        MLflow Tracking
                                    </h2>
                                    <p>
                                        MLflow's TensorFlow autologging is
                                        enabled to automatically track:
                                    </p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            Model architecture and
                                            configuration.
                                        </li>
                                        <li>
                                            Training parameters (e.g., epochs,
                                            batch size).
                                        </li>
                                        <li>
                                            Metrics such as loss values over
                                            epochs.
                                        </li>
                                    </ul>
                                    <p>
                                        The trained model is saved as an H5 file
                                        (<code>my_model.h5</code>) and logged to
                                        MLflow for further use.
                                    </p>
                                </li>

                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Run ID
                                    </h2>
                                    <p>
                                        A unique <code>run_id</code> is
                                        generated for each training session,
                                        enabling tracking and reproducibility.
                                    </p>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Deployment Preparation
                                    </h2>
                                    <p>
                                        The saved model and its configurations
                                        are prepared for deployment:
                                    </p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>
                                                MLServer Configuration
                                            </strong>
                                            : Specifies the runtime (
                                            <code>
                                                mlserver_mlflow.MLflowRuntime
                                            </code>
                                            ) and model URI.
                                        </li>
                                        <li>
                                            <strong>Dockerfile</strong>: Creates
                                            a containerized environment for the
                                            model with dependencies like
                                            TensorFlow and MLServer.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Docker Image Creation and Publishing
                                    </h2>
                                    <p>
                                        The model is containerized using Docker:
                                    </p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            The saved model, configuration
                                            files, and dependencies are packaged
                                            into a Docker image.
                                        </li>
                                        <li>
                                            The image is optionally pushed to a
                                            Docker registry for remote
                                            deployment.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Model Prediction
                                    </h2>
                                    <p>
                                        After deployment, the model can accept
                                        JSON inputs for prediction. For example:
                                    </p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>Input JSON</strong>:
                                            Contains an array of 10 features for
                                            inference.
                                        </li>
                                        <li>
                                            <strong>Output</strong>: The model
                                            predicts a scalar value based on the
                                            input.
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                            <p className="font-semibold">Summary</p>
                            <p className='my-2'>
                                This workflow demonstrates how to build a robust
                                pipeline for training, tracking, and deploying
                                TensorFlow models using MLflow. It highlights
                                best practices for versioning, reproducibility,
                                and scaling machine learning workflows in
                                production. The modular approach ensures
                                seamless integration with tools like Docker and
                                MLServer for efficient deployment on Vipas.AI.
                            </p>   
                            <Navigation
                       previous={"Previous: Wine Quality Prediction Using ElasticNet Regression"} previousNav={"wine-quality-prediction"}
                       next={"Next: Movie Recommendation System Training and Deployment"}
                       nextNav={"movie-recommendation"}
                   />               
               </div>
               <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16">
                   {/* <p className="font-semibold my-8 ">On this page</p>
                   <ul className="border-t-2 py-4">
                       <li className="link-list ">
                           <a href="#supported-file-types" className="links">
                               Supported File Types
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#directory-structure" className="links">
                               Directory Structure
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#model-configuration" className="links">
                               Model Configuration
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#summary-workflow" className="links">
                               Summary Workflow
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#examples" className="links">
                               Examples
                           </a>
                       </li>
                   </ul> */}
               </div>
           </div>
   </>
  )
}

export default TensorflowModelTraining
