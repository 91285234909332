import React from "react";
import { CopyBlock, anOldHope } from "react-code-blocks";
import downloadIcon from "../../../assets/images/download.png";
import ragImage from "../../../assets/images/rag-app/image9.gif"
import flowChartImg from  "../../../assets/images/rag-app/image6.webp"
import createMenuImg from "../../../assets/images/rag-app/image1.webp"
import registerImg from "../../../assets/images/rag-app/image3.webp"
import createImg from "../../../assets/images/rag-app/image4.webp"
import pullAppImg from "../../../assets/images/rag-app/image2.webp"
import reviewImg from "../../../assets/images/rag-app/image8.webp"
import manageProjectImg from "../../../assets/images/rag-app/image5.webp"
import buildImg from "../../../assets/images/rag-app/image7.webp"
import Navigation from "../../Navigation";
import { scrollToId } from "../../../utils/reusableFunctions";

function RagBasedStreamlitApp() {
    const codeSnippets = {
        documentProcessing: `if file.type == "application/pdf":
    with pdfplumber.open(file) as pdf:
        text = "".join([page.extract_text() or "" for page in pdf.pages])
elif file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    doc = Document(file)
    text = " ".join([para.text for para in doc.paragraphs])
elif file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    data = pd.read_excel(file)
    text = data.to_string(index=False)`,
        embeedingGenerations: `self.chunks = [text[i:i + 500] for i in range(0, len(text), 500)]
self.faiss_index = faiss.IndexFlatL2(384)
self.embeddings = []
for i in range(0, len(self.chunks), batch_size):
    batch = self.chunks[i:i + batch_size]
    batch_embeddings = self.embedding_model.encode(batch)
    self.embeddings.extend(batch_embeddings)`,
queryLLM: `def query_llm(self, query, context):
    if not context.strip():
        logger.warning("No relevant context found. Querying LLM without context.")
        context = "No additional context available."

    prompt = (
        "You are an expert. Answer the question using the provided context:\n\n"
        f"Context: {context}\n\n"
        f"Question: {query}\n\n"
        "Answer:"
    )

    payload = {
        "inputs": [
            {
                "name": "prompt",
                "shape": [1],
                "datatype": "BYTES",
                "data": [prompt]
            }
        ]
    }

    try:
        response = self.client.predict(model_id=self.model_id, input_data=payload)
        return response.get("outputs", [{}])[0].get("data", ["No response text available."])[0]
    except Exception as e:
        st.error(f"Error querying the LLM: {e}")
        return ""
`,
finalCode: `import streamlit as st
from vipas import model, logger
from sentence_transformers import SentenceTransformer
import faiss
import pdfplumber
from docx import Document
import pandas as pd
import numpy as np
from transformers import AutoTokenizer

class RAGProcessor:
    def __init__(self, model_id):
        self.client = model.ModelClient()
        self.model_id = model_id
        self.embedding_model = SentenceTransformer("all-MiniLM-L6-v2")
        self.tokenizer = AutoTokenizer.from_pretrained("bert-base-uncased")
        self.faiss_index = None
        self.chunks = []
        self.embeddings = None
        self.last_file_name = None

    def preprocess_document(self, file):
        try:
            if file.type == "application/pdf":
                with pdfplumber.open(file) as pdf:
                    text = "".join([page.extract_text() or "" for page in pdf.pages])
            elif file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                doc = Document(file)
                text = " ".join([para.text for para in doc.paragraphs])
            elif file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                data = pd.read_excel(file)
                text = data.to_string(index=False)
            else:
                st.error("Unsupported file type. Please upload a PDF, DOCX, or Excel file.")
                return ""
            return text
        except Exception as e:
            st.error(f"Error processing file: {e}")
            return ""

    def store_embeddings(self, text, batch_size=32):
        self.chunks = [text[i:i + 500] for i in range(0, len(text), 500)]
        self.chunks = [chunk for chunk in self.chunks if chunk.strip()]

        if not self.chunks:
            st.error("No valid text found in the document.")
            return None

        self.faiss_index = faiss.IndexFlatL2(384)  # Reinitialize FAISS index
        self.embeddings = []

        for i in range(0, len(self.chunks), batch_size):
            batch = self.chunks[i:i + batch_size]
            batch_embeddings = self.embedding_model.encode(batch)
            self.embeddings.extend(batch_embeddings)

        self.embeddings = np.array(self.embeddings)
        self.faiss_index.add(self.embeddings)
        return self.chunks

    def retrieve_context(self, query):
        if self.faiss_index is None or not self.chunks or self.faiss_index.ntotal == 0:
            logger.warning("No document is indexed. Proceeding without context.")
            return ""

        query_embedding = self.embedding_model.encode([query])
        distances, indices = self.faiss_index.search(query_embedding, k=5)  # Retrieve top 5 chunks
        retrieved_chunks = [self.chunks[i] for i in indices[0] if i < len(self.chunks)]
        
        if not retrieved_chunks:
            logger.warning("No relevant context found for the query. Proceeding without context.")
            return ""

        retrieved_text = " ".join(retrieved_chunks)

        # Tokenize the retrieved context to 50 tokens
        tokenized_text = self.tokenizer.tokenize(retrieved_text)
        truncated_text = self.tokenizer.convert_tokens_to_string(tokenized_text[:50])
        return truncated_text

    def query_llm(self, query, context):
        if not context.strip():
            logger.warning("No relevant context found. Querying LLM without context.")
            context = "No additional context available."

        prompt = (
            "You are an expert. Answer the question using the provided context:\n\n"
            f"Context: {context}\n\n"
            f"Question: {query}\n\n"
            "Answer:"
        )

        payload = {
            "inputs": [
                {
                    "name": "prompt",
                    "shape": [1],
                    "datatype": "BYTES",
                    "data": [prompt]
                }
            ]
        }

        try:
            response = self.client.predict(model_id=self.model_id, input_data=payload)
            return response.get("outputs", [{}])[0].get("data", ["No response text available."])[0]
        except Exception as e:
            st.error(f"Error querying the LLM: {e}")
            return ""

# Use Streamlit session state to persist the processor
if "rag_processor" not in st.session_state:
    st.session_state.rag_processor = RAGProcessor(model_id="mdl-b1mxve8nrq9cj")

if "response" not in st.session_state:
    st.session_state.response = ""

rag_processor = st.session_state.rag_processor

# Streamlit app
st.markdown("<h1 style='text-align: center;'>DocQuery-AI</h1>", unsafe_allow_html=True)
st.markdown("<h3>RAG-based Q&A app using Llama with PDF, DOC, Excel input.</h3>", unsafe_allow_html=True)
st.write("Upload a document (PDF, DOC, or Excel) under 2 MB and ask questions using the LLM.")

# File upload
uploaded_file = st.file_uploader("Upload a file (PDF, DOC, or Excel):", type=["pdf", "docx", "xlsx"])
if uploaded_file:
    file_size = uploaded_file.size / (1024 * 1024)
    if file_size > 2:
        st.error("File size exceeds 2MB. Please upload a smaller file.")
    else:
        file_name = uploaded_file.name
        if file_name != rag_processor.last_file_name:
            st.write("Uploading the file...")
        if uploaded_file or file_name == rag_processor.last_file_name:
            st.markdown("<p><strong>File Uploaded.</strong></p>", unsafe_allow_html=True)

        submit_button = st.button("Submit", disabled=not bool(uploaded_file), key="submit_button")
        if submit_button and uploaded_file:
            text = rag_processor.preprocess_document(uploaded_file)
            if text:
                st.markdown("<p><strong>Generating embeddings and indexing...</strong></p>", unsafe_allow_html=True)
                chunks = rag_processor.store_embeddings(text)
                if chunks:
                    rag_processor.last_file_name = file_name
                    st.success("Document processed and indexed successfully!")

if rag_processor.last_file_name and rag_processor.faiss_index is not None:
    query = st.text_input("Enter your query:")

    col1, col2 = st.columns([8, 1])
    with col2:
        query_button = st.button("Query", key="query_button", type="primary")

    if query and query_button:
        context = rag_processor.retrieve_context(query)  # Retrieve context
        st.markdown("<p><strong>Generating response from LLM...</strong></p>", unsafe_allow_html=True)
        st.session_state.response = rag_processor.query_llm(query, context)  # Query LLM even if context is empty

if st.session_state.response:
    st.write("### Response")
    st.write(st.session_state.response)`,
    };
    return (
        <>
            <div className="flex my-8 relative break-words">
                <div className="w-[100%] xl:w-[66.66%] px-[24px]">
                    <h1 className="mb-3 heading">
                    Harnessing LLMs Based on RAG in Streamlit: Monetizing a
                        Smart Q&A App on Vipas.AI
                    </h1>
                    <div className="border rounded-[5px] w-[250px] p-2 shadow-md my-2 hover:bg-gray-100">
                               <a
                                   href="https://utils.vipas.ai/vps-ipynb/RAG_using_llama/RAG.zip"
                                   target="_blank"
                                   className="ml-3 flex items-center"
                               >
                                   <img
                                       src={downloadIcon}
                                       width={32}
                                       height={32}
                                       alt="text-summarization-download"
                                   />{" "}
                                   <span className="ml-2">
                                       Download Notebook
                                   </span>
                               </a>
                           </div>
                    <div className="my-2">
                        <h3 className="mini-heading">Table of Contents</h3>
                        <ol className="list-inside list-decimal">
                            <li className="links " onClick={()=>scrollToId('introduction')}>Introduction</li>
                            <li className="links " onClick={()=>scrollToId('rag')}>What is RAG, and Why Does It Matter?</li>
                            <li className="links " onClick={()=>scrollToId('architecture')}>Architecture Overview</li>
                            <li className="links " onClick={()=>scrollToId('application-working')}>How the Application Works</li>
                            <li className="links " onClick={()=>scrollToId('components')}>
                                Deep Dive into Components
                                <ul className="list-disc list-inside">
                                    <li>Document Processing</li>
                                    <li>Embedding Generation and Indexing</li>
                                    <li>Querying the LLM for Answers</li>
                                    <li>Final code</li>
                                </ul>
                            </li>
                            <li className="links " onClick={()=>scrollToId('vipas-predict')}>Understanding the VipasSDK predict Method</li>
                            <li className="links " onClick={()=>scrollToId('deploying-monetizing')}>
                                Deploying and Monetizing the App on Vipas.AI
                            </li>
                            <li className="links " onClick={()=>scrollToId('conclusion')}>Conclusion</li>
                            <li className="links " onClick={()=>scrollToId('why-vipas')}>Why Monetizing Your AI on Vipas.AI Can Be Life-Changing</li>
                        </ol>
                    </div>
                    <div className="my-6" id="introduction">
                        <h2 className="sub-heading">Introduction</h2>
                        <h3 className="mini-heading">
                            Understanding the Need for RAG in LLMs
                        </h3>
                        <p className="my-2">
                            Large Language Models (LLMs) have transformed the
                            way AI interacts with users, providing insightful
                            responses to queries in real-time. With the rise of
                            Large Language Models (LLMs), users are demanding
                            AI-powered solutions that provide precise,
                            context-aware answers. However, a common limitation
                            of LLMs is their reliance on static training data,
                            which may not include the latest information or
                            domain-specific knowledge.{" "}
                            <strong>
                                Retrieval-Augmented Generation (RAG)
                            </strong>{" "}
                            addresses this issue by integrating external
                            document retrieval with generative AI, ensuring
                            responses are more accurate and grounded in
                            up-to-date information.
                        </p>
                        <h3 className="mini-heading">
                            The Role of Vipas.AI in AI-Powered Solutions
                        </h3>
                        <p className="my-2">
                            Vipas.AI offers a robust platform to deploy AI
                            applications efficiently, enabling seamless
                            integration with large-scale inference models. By
                            leveraging <strong>VipasSDK</strong>, developers can
                            easily integrate LLM capabilities while ensuring
                            real-time, accurate, and document-aware responses.
                        </p>
                        <h3 className="mini-heading">
                            Monetize Your AI Expertise & Get Discovered by
                            Enterprises on Vipas.AI!
                        </h3>
                        <p className="my-2">
                            AI is evolving fast, and{" "}
                            <strong>
                                domain-specific AI applications are becoming an
                                industry necessity
                            </strong>
                            . The ability to{" "}
                            <strong>
                                build, deploy, and monetize AI-powered apps
                            </strong>{" "}
                            is no longer limited to large tech firms—
                            <strong>
                                you can start monetizing your AI innovations
                                today!
                            </strong>
                        </p>
                        <ul className="my-2 list-disc list-inside">
                            <li>
                                {" "}
                                <strong>
                                    Turn Your AI Solutions into Revenue Streams
                                </strong>{" "}
                                – Instead of just experimenting with AI,{" "}
                                <strong>
                                    publish your RAG-based applications and
                                    charge per use.
                                </strong>
                            </li>
                            <li>
                                {" "}
                                <strong>
                                    Get Industry Recognition & Career
                                    Opportunities
                                </strong>{" "}
                                – When enterprises and recruiters see your AI
                                models in action,{" "}
                                <strong>
                                    you attract attention, job offers, and
                                    investment
                                </strong>{" "}
                                without needing a traditional resume.
                            </li>
                            <li>
                                <strong>
                                    Empower SMBs & Enterprises with Your AI
                                </strong>{" "}
                                – A diagnostic center, legal firm, or research
                                lab can{" "}
                                <strong>
                                    publish a smart Q&A model and monetize
                                    industry-specific AI solutions globally
                                </strong>
                                .
                            </li>
                            <li>
                                <strong>Seize the First-Mover Advantage</strong>{" "}
                                – AI marketplaces are the future, but most
                                platforms only host code.{" "}
                                <strong>
                                    Vipas.AI lets you publish and run live AI
                                    models
                                </strong>
                                , giving you{" "}
                                <strong>
                                    early dominance in AI monetization
                                </strong>
                                .
                            </li>
                        </ul>
                        <p className="my-2">
                            In this guide, you’ll learn how to{" "}
                            <strong>
                                deploy a RAG-powered LLM in a Streamlit app
                            </strong>{" "}
                            and <strong>monetize it instantly</strong>. By the
                            end, you’ll have a fully functional AI app that
                            businesses can use while you{" "}
                            <strong>earn per interaction</strong>. Let’s dive
                            in!
                        </p>

                        <p className="font-semibold">What This Blog Covers</p>
                        <p>
                            In this comprehensive guide, we will walk through
                            the entire process of deploying a{" "}
                            <strong>
                                RAG-based LLM Streamlit application on Vipas.AI
                            </strong>
                            . This includes:
                        </p>
                        <ul className="list-disc list-inside my-2 font-semibold">
                            <li>
                                Extracting text from uploaded documents (PDF,
                                DOCX, Excel).
                            </li>
                            <li>
                                Generating vector embeddings using Sentence
                                Transformers.
                            </li>
                            <li>
                                Storing and retrieving text using FAISS
                                indexing.
                            </li>
                            <li>Using VipasSDK for LLM-powered responses.</li>
                            <li>
                                Deploying the complete application on Vipas.AI.
                            </li>
                        </ul>
                        <p>
                            By the end of this guide, you will have a working
                            AI-powered app capable of retrieving knowledge from
                            documents and generating intelligent responses with
                            real-time accuracy.
                        </p>
                    </div>

                    <div className="my-6" id="rag">
                        <h2 className="sub-heading">
                            What is RAG, and Why Does It Matter?
                        </h2>
                        <p className="my-2">
                            Retrieval-Augmented Generation (RAG) is an advanced
                            AI framework that seamlessly integrates traditional
                            information retrieval techniques, such as search
                            engines and databases, with the generative power of
                            large language models (LLMs). By dynamically
                            combining structured data with the contextual
                            understanding of LLMs, RAG enhances response
                            accuracy, ensures real-time relevance, and tailors
                            content generation to your specific needs. This
                            synergy enables AI to provide more informed,
                            precise, and context-aware outputs, making it a
                            powerful tool for knowledge-driven applications.
                        </p>
                        <p className="font-semibold">
                            How does Retrieval-Augmented Generation work?{" "}
                        </p>
                        <p>
                            RAGs operate with a few main steps to help enhance
                            generative AI outputs:
                        </p>
                        <ul className="list-inside list-disc my-2">
                            <li>
                                <strong>Retrieval and pre-processing</strong>:
                                Retrieval-Augmented Generation (RAG) employs
                                advanced search algorithms to efficiently query
                                external data sources, including web pages,
                                structured knowledge bases, and databases. Once
                                relevant information is retrieved, it undergoes
                                a rigorous pre-processing pipeline involving
                                tokenization, stemming, lemmatization, and
                                stop-word removal. This refinement ensures that
                                only the most meaningful and contextually
                                relevant data is retained, optimizing input
                                quality for the language model.
                            </li>
                            <li>
                                <strong>Grounded generation</strong>: The
                                processed information is then seamlessly
                                integrated into the pre-trained Large Language
                                Model (LLM), enriching its contextual
                                understanding and expanding its knowledge beyond
                                pre-existing training data. By anchoring
                                generation to real-time, authoritative sources,
                                this approach significantly enhances response
                                accuracy, coherence, and depth. The result is a
                                more precise, context-aware, and engaging
                                AI-generated output that is not only informative
                                but also tailored to the user’s specific needs.
                            </li>
                        </ul>
                        <img src={ragImage} loading="lazy" className='my-6 tw-w-full tw-h-auto tw-object-contain' alt="rag-image"/>
                        <p>
                            Retrieval-Augmented Generation (RAG) functions by
                            first retrieving highly relevant information from a
                            database using a query dynamically generated by the
                            Large Language Model (LLM). This retrieved data is
                            then seamlessly incorporated into the LLM's input,
                            enriching its contextual understanding and enabling
                            it to generate more precise, informative, and
                            contextually relevant responses. The retrieval
                            process is typically powered by a semantic search
                            engine that leverages embeddings stored in vector
                            databases, combined with advanced ranking algorithms
                            and query optimization techniques. These
                            sophisticated mechanisms ensure that the retrieved
                            content is not only highly relevant to the query but
                            also enhances the model’s ability to provide
                            accurate, well-grounded, and insightful answers.
                        </p>

                        <h3 className="mini-heading my-2">Why Use RAG?</h3>
                        <p>
                            RAG enhances traditional text generation by
                            integrating real-time, factual data into LLM
                            responses. Here’s why it’s beneficial:
                        </p>
                        <ul className="list-inside list-disc">
                            <li>
                                <strong>Access to Fresh Information</strong>:
                                LLMs are limited to pre-trained data, leading to
                                outdated responses. RAG enables real-time
                                retrieval of updated information.
                            </li>
                            <li>
                                <strong>Factual Grounding</strong>: Mitigates
                                hallucinations by ensuring responses are based
                                on reliable, retrieved facts.
                            </li>
                            <li>
                                <strong>Efficient Search</strong>: Uses vector
                                databases and hybrid search (semantic + keyword)
                                for accurate, relevant retrieval.
                            </li>
                            <li>
                                <strong>Improved Accuracy & Quality</strong>:
                                Fine-tuning ensures responses align with
                                retrieved knowledge, minimizing inconsistencies.
                            </li>
                            <li>
                                <strong>Versatile Applications</strong>:
                                Enhances chatbots, agents, and AI applications
                                needing dynamic, private, or domain-specific
                                knowledge.
                            </li>
                        </ul>
                        <p>
                            By integrating RAG, LLMs become more reliable,
                            relevant, and scalable, ensuring optimal performance
                            for various AI applications.
                        </p>

                        <h3 className="mini-heading my-2">
                            What is document retrieval based RAG?
                        </h3>
                        <p>
                            Retrieval-Augmented Generation (RAG) combines
                            information retrieval with generative AI to provide
                            accurate responses. Instead of relying solely on
                            pre-trained knowledge, a RAG-based model retrieves
                            relevant context from uploaded documents before
                            formulating an answer, significantly improving
                            accuracy for domain-specific queries.
                        </p>
                        <h3 className="mini-heading my-2">
                            How document retrieval based RAG Works:
                        </h3>
                        <ol className="list-decimal list-inside">
                            <li>
                                <strong>Document Processing</strong>: Text is
                                extracted from PDFs, DOCX, or Excel files.
                            </li>
                            <li>
                                <strong>Embedding Generation</strong>: Sentences
                                are converted into numerical vectors using
                                Sentence Transformers.
                            </li>
                            <li>
                                <strong>FAISS Indexing</strong>: Embeddings are
                                stored and indexed for fast retrieval.
                            </li>
                            <li>
                                <strong>Query Execution</strong>: When a user
                                submits a query, the closest matching text
                                chunks are retrieved.
                            </li>
                            <li>
                                <strong>LLM Processing</strong>: The retrieved
                                context is fed into the LLM, which generates a
                                precise response.
                            </li>
                        </ol>
                    </div>

                    <div className="my-6 " id="architecture">
                        <h2 className="sub-heading">Architecture Overview</h2>
                        <h4 className="mini-heading">System Flowchart:</h4>
                        <img src={flowChartImg} loading="lazy" className='my-6 tw-w-full tw-h-auto tw-object-contain' alt="flow-chart"/>

                    </div>

                    <div className="my-6" id="application-working">
                        <h2 className="sub-heading">
                            How the Application Works
                        </h2>
                        <p>
                            The application is built using{" "}
                            <strong>Streamlit</strong>, integrated with:
                        </p>
                        <ul className="list-inside list-disc my-2">
                            <li>
                                <strong>Vipas ModelClient API</strong> for LLM
                                inference.
                            </li>
                            <li>
                                <strong>Sentence Transformers</strong> for
                                embedding generation.
                            </li>
                            <li>
                                <strong>FAISS</strong> for similarity search.
                            </li>
                            <li>
                                <strong>Document processing libraries</strong>{" "}
                                like <code>pdfplumber</code>,{" "}
                                <code>python-docx</code>, and{" "}
                                <code>pandas</code>.
                            </li>
                        </ul>
                    </div>
                    <div className="my-6" id="components">
                        <h2 className="sub-heading">
                            Deep Dive into Components
                        </h2>
                        <h3 className="mini-heading">Document Processing</h3>
                        <p>
                            Users can upload a PDF, DOCX, or Excel file
                            containing relevant data. The application extracts
                            text from:
                        </p>
                        <div className="w-full my-4">
                            <CopyBlock
                                text={codeSnippets.documentProcessing}
                                language="python"
                                showLineNumbers={false}
                                theme={anOldHope}
                                wrapLines={true}
                                codeBlock
                            />
                        </div>
                        <p>
                            This code snippet demonstrates how the system
                            extracts text from different document formats:
                        </p>
                        <ul className="list-inside list-disc">
                            <li>
                                <strong>PDF files</strong> using{" "}
                                <code>pdfplumber</code>.
                            </li>
                            <li>
                                <strong>Word documents</strong> using{" "}
                                <code>python-docx</code>.
                            </li>
                            <li>
                                <strong>Excel files</strong> using{" "}
                                <code>pandas</code>.
                            </li>
                        </ul>
                    </div>
                    <div className="my-6">
                        <h3 className="mini-heading">Embedding Generation</h3>
                        <p>
                            To enable fast and accurate retrieval, the
                            application:
                        </p>
                        <div className="w-full my-4">
                            <CopyBlock
                                text={codeSnippets.embeedingGenerations}
                                language="python"
                                showLineNumbers={false}
                                theme={anOldHope}
                                wrapLines={true}
                                codeBlock
                            />
                        </div>
                        <p>This snippet explains:</p>
                        <ul className="my-2 list-disc list-inside">
                            <li>
                                Splitting extracted text into{" "}
                                <strong>500-character</strong> chunks for
                                processing.
                            </li>
                            <li>
                                Using <strong>Sentence-BERT</strong> to generate
                                vector embeddings.
                            </li>
                            <li>
                                Storing embeddings in a{" "}
                                <strong>FAISS Index</strong> for efficient
                                similarity searches.
                            </li>
                        </ul>
                        <h3 className="mini-heading">
                            Querying the LLM for Answers
                        </h3>
                        <p>Once a document is indexed:</p>
                        <div className="w-full my-4">
                            <CopyBlock
                                text={codeSnippets.queryLLM}
                                language="python"
                                showLineNumbers={false}
                                theme={anOldHope}
                                wrapLines={true}
                                codeBlock
                            />
                        </div>
                        <p>This function:</p>
                        <ul className="list-inside list-disc">
                            <li>
                                Constructs a prompt using retrieved document
                                context.
                            </li>
                            <li>
                                Calls the LLM API to generate an intelligent
                                response.
                            </li>
                            <li>
                                Extracts and returns the model’s textual answer.
                            </li>
                        </ul>
                        <h3 className="mini-heading">Final Code</h3>
                        <div className="w-full my-4">
                            <CopyBlock
                                text={codeSnippets.finalCode}
                                language="python"
                                showLineNumbers={false}
                                theme={anOldHope}
                                wrapLines={true}
                                codeBlock
                            />
                        </div>
                    </div>

                    <div className="my-6" id="vipas-predict">
                        <h3 className="sub-heading">
                            {" "}
                            Understanding the VipasSDK <code>predict</code>{" "}
                            Method
                        </h3>
                        <p>
                            The <code>predict</code> method in VipasSDK is used
                            to send a structured prompt to the LLM and retrieve
                            a relevant response. Here’s how it works:
                        </p>
                        <ol className="list-inside list-decimal my-4">
                            <li>
                                The <strong>prompt</strong> is created
                                dynamically using the retrieved document context
                                and user query.
                            </li>
                            <li>
                                The <code>client.predict()</code> function calls
                                the LLM hosted on the Vipas AI platform.
                            </li>
                            <li>
                                The <strong>response</strong> is parsed to
                                extract the relevant text output from the LLM.
                            </li>
                        </ol>
                        <p>This function ensures:</p>
                        <ul className="list-decimal list-inside my-4">
                            <li>
                                <strong>Context-Aware Responses</strong>: The
                                model answers queries based on uploaded
                                documents.
                            </li>
                            <li>
                                <strong>Real-Time Processing</strong>: The API
                                call returns immediate results.
                            </li>
                            <li>
                                <strong>Scalability</strong>: Can be used across
                                multiple queries dynamically.
                            </li>
                        </ul>
                    </div>

                    <div className="my-6" id="deploying-monetizing">
                        <h2 className="sub-heading">
                            Deploying and Monetizing the App on Vipas.AI
                        </h2>
                        <p>
                            Vipas.AI enables AI developers to seamlessly deploy,
                            manage, and monetize their applications. Follow the
                            steps below to create, deploy, and launch your
                            AI-powered app.
                        </p>
                        <h4 className="my-2 mini-heading">
                            Register and Log in to Vipas.AI
                        </h4>
                        <ol className="list-inside list-decimal my-4">
                            <li>
                                Visit{" "}
                                <a href="https://vipas.ai/signup">Vipas.AI</a>{" "}
                                and create an account if you haven’t already.
                            </li>
                            <li>
                                <a href="https://vipas.ai/login">Log in</a>{" "}
                                using your credentials.
                                <img src={registerImg} loading="lazy" className='my-6 tw-w-full tw-h-auto tw-object-contain' alt="signup"/>

                            </li>
                        </ol>
                        <h4 className="my-2 mini-heading">
                            Initiate App Creation
                        </h4>
                        <ol className="list-inside list-decimal my-4">
                            <li>
                                Click on the "<strong>Create</strong>" button
                                from the top navigation bar.
                            </li>
                            <li>
                                Select “<strong>App</strong>” from the dropdown
                                menu.
                            </li>
                            <li>
                                You will be redirected to the{" "}
                                <strong>App Creation</strong> page.
                                <img src={createMenuImg} loading="lazy" className='my-6 tw-w-full tw-h-auto tw-object-contain' alt="create-menu"/>

                            </li>
                            
                        </ol>
                        <h4 className="my-2 mini-heading">
                            Provide Project Details
                        </h4>
                        <ol className="list-inside list-decimal my-4">
                            <li>
                                Fill in the required fields to define your app:
                                <ul className="list-inside list-disc">
                                    <li>
                                        <strong>Project Name</strong> – Choose a
                                        unique and descriptive name for your
                                        app.
                                    </li>
                                    <li>
                                        <strong>Category</strong> – Select the
                                        most relevant category that fits your
                                        app.
                                    </li>
                                    <li>
                                        <strong>Price</strong> (if{" "}
                                        <strong>Run Access Permission</strong>{" "}
                                        is set to <code>public</code>) – Set a
                                        price to monetize your app.
                                    </li>
                                    <li>
                                        <strong>Permissions</strong> – Define
                                        access permissions:
                                        <ul className="ml-2 list-inside list-disc">
                                            <li>
                                                <code>Public</code> – Open to
                                                all users.
                                            </li>
                                            <li>
                                                <code>Private</code> –
                                                Restricted to only authorized
                                                users.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Description</strong> – Provide a
                                        concise and informative description of
                                        your app.
                                    </li>
                                    <li>
                                        <strong>Authorized Model</strong> –
                                        Enter the <strong>Model ID</strong> of
                                        the deployed LLM on Vipas.AI.
                                        <div className="my-4 rounded-[.375rem] border border-[#d7eaf8] bg-note p-[1rem]">
                                            <p className="font-semibold text-note">
                                                Note: The selected model must
                                                meet the following criteria:
                                            </p>
                                            <ul className="list-inside list-disc">
                                                <li>
                                                    It should have{" "}
                                                    <strong>
                                                        API access set to Public
                                                    </strong>
                                                    .
                                                </li>
                                                <li>
                                                    The model should be{" "}
                                                    <strong>
                                                        successfully launched
                                                    </strong>{" "}
                                                    on Vipas.AI.
                                                </li>
                                            </ul>
                                           

                                        </div>
                                        <img src={createImg} loading="lazy" className='my-6 tw-w-full tw-h-auto tw-object-contain' alt="create-project"/>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Once all details are filled, click on the “
                                <strong>Create</strong>” button.
                            </li>
                        </ol>
                        <h4 className="my-2 mini-heading">
                            Configure the App Build
                        </h4>
                        <ol className="list-decimal list-inside my-4">
                            <li>
                                After successfully creating the project, a{" "}
                                <strong>success notification</strong> (via
                                Toastify) will be displayed and the user will be
                                navigated to the App page.
                            </li>
                            <li>
                                Select a <strong>Base Image</strong> suitable
                                for your application.
                            </li>
                            <li>
                                Provide the following GitHub repository details:
                                <ul className="ml-2 list-inside list-disc">
                                    <li>
                                        GitHub Repository URL – Enter the public
                                        repository link where your app code is
                                        hosted.
                                    </li>
                                    <li>
                                        <strong>PAT</strong> – Provide a Github
                                        Personal Access Token (PAT) for
                                        authentication.
                                    </li>
                                    <li>
                                        <strong> Note</strong>: Ensure that your
                                        repository contains only a single file
                                        named app.py.
                                    </li>
                                </ul>
                                <img src={pullAppImg} loading="lazy" className='my-6 tw-w-full tw-h-auto tw-object-contain' alt="app-pull"/>
                            </li>
                            <li>
                                Click on the "<strong>Pull</strong>" button to
                                fetch the <code>app.py</code> file.
                            </li>
                        </ol>
                        <h4 className="my-2 mini-heading">
                            Build the Application
                        </h4>
                        <ol className="list-decimal list-inside my-4">
                            <li>
                                Once the <code>app.py</code> file is
                                successfully pulled, click on the "
                                <strong>Build</strong>" button to initiate the
                                build process.
                                <img src={buildImg} loading="lazy" className='my-6 tw-w-full tw-h-auto tw-object-contain' alt="app-build"/>
                            </li>
                            <li>
                                The system will start packaging and setting up
                                the application environment.
                            </li>
                            <li>
                                After initiating the build, click on "
                                <strong>Next</strong>" to proceed.
                            </li>
                        </ol>
                        <h4 className="my-2 mini-heading">
                            Review and Finalize Deployment
                        </h4>
                        <ol className="list-decimal list-inside my-4">
                            <li>Review the details of your app setup.</li>
                            <li>
                                If everything looks good, click on the "
                                <strong>Finish</strong>" button.
                                <img src={registerImg} loading="lazy" className='my-6 tw-w-full tw-h-auto tw-object-contain' alt="review-image"/>
                            </li>
                            <li>
                                You will be redirected to the{" "}
                                <strong>Manage Projects</strong> page, where all
                                your created projects are listed.
                            </li>
                        </ol>
                        <h4 className="my-2 mini-heading">
                            Deploy and Launch the App
                        </h4>
                        <ol className="list-decimal list-inside my-4">
                            <li>
                                Wait for the <strong>App Status</strong> to
                                change to “<strong>Staged</strong>” (this
                                indicates the app is ready for launch).
                            </li>
                            <li>
                                Once the app is staged:
                                <ul className="list-inside list-disc my-2">
                                    <li>
                                        Click on the <strong>three dots</strong>{" "}
                                        (⋮) next to your app.
                                    </li>
                                    <li>
                                        Select "<strong>Launch</strong>" from
                                        the dropdown menu.
                                    </li>
                                </ul>
                                <img src={manageProjectImg} loading="lazy" className='my-6 tw-w-full tw-h-auto tw-object-contain' alt="manage-projects"/>

                            </li>
                            <li>The app will now begin deployment.</li>
                            <li>
                                Once the App Status changes to “Launched”, your
                                app is live.
                            </li>
                        </ol>
                        <h4 className="my-2 mini-heading">
                            Run the Application
                        </h4>
                        <ol className="list-inside list-decimal my-2">
                            <li>Click on the app to open it.</li>
                            <li>
                                Press the "<strong>Run</strong>" button to
                                execute and interact with your app.
                            </li>
                        </ol>
                        <p className="my-4">
                            💡 <strong>Congratulations!</strong> Your AI app is
                            now deployed on Vipas.AI, and users can access and
                            utilize it based on the permissions you set. If you
                            opted for monetization, users will need to pay
                            before running your app, allowing you to earn
                            revenue.
                        </p>
                        <p>
                            For further support, visit the{" "}
                            <a
                                href="https://docs.vipas.ai/developer-docs/steps-to-create-an-app"
                                className="links !text-[16px]"
                                target="_blank"
                            >
                                Vipas.AI Documentation
                            </a>
                            .
                        </p>
                    </div>
                    <div className="my-6" id="conclusion">
                        <h2 className="sub-heading">Conclusion</h2>
                        <p>Deploying and Monetizing a RAG-based LLM Streamlit app on Vipas.AI enables fast, accurate document-based knowledge retrieval. By integrating FAISS indexing and VipasSDK-powered LLM responses, we achieve a scalable, efficient AI-powered application that transforms document search into meaningful insights.</p>
                    </div>
                    <div className="my-6" id="why-vipas">
                        <h2 className="sub-heading">Why Monetizing Your AI on Vipas.AI Can Be Life-Changing</h2>
                        <p>You’ve successfully built a RAG-based LLM application—now what? Instead of keeping it as a side project, transform it into a revenue-generating AI asset!</p>
                        <p>💰 Earn Every Time Someone Uses Your App – Unlike traditional AI research or development, you get paid per API call or per user interaction, generating continuous revenue.</p>
                        <p>💼 Become an AI Thought Leader & Get Hired Without Applying – Recruiters don’t just look at resumes anymore. When enterprises use your AI solutions on Vipas.AI, you automatically become a top AI hire.
</p>
<p>🏆 Build an AI Business Without Infrastructure Costs – No need to set up expensive GPU servers or manage DevOps. Vipas.AI takes care of the hosting, scaling, and billing while you focus on AI innovation.
</p>
<p>🔗 Attract Industry Partnerships & Funding – AI investors and enterprises scout the best AI solutions on Vipas.AI. By deploying your app, you increase your chances of securing partnerships, licensing deals, and funding.
</p>
<p>🔥 Take Control of Your AI Career – Instead of working on AI for someone else, own your AI work, earn money, and establish your presence in the global AI industry.
</p>
<p>Don’t just build AI—MONETIZE it! Sign up on Vipas.AI today and start turning your AI knowledge into real-world success.

</p>
                    </div>
                    <Navigation
                        previous={"Previous: Deploying LLaMA Model Using MLflow on Vipas.AI: A Comprehensive Guide"}
                        previousNav={"deploying-llama-using-mlflow"}
                       next={"Next: Deploying a PyTorch model on Vipas.AI"}
                       nextNav={"pytorch-model-with-mlflow"}
                   />
                </div>
                <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16">
                    <p className="font-semibold my-8 ">On this page</p>
            <ul className="border-t-2 py-4">
                <li className="link-list  links" onClick={()=>scrollToId('introduction')}>
                Introduction
                </li>
                <li className="link-list links" onClick={()=>scrollToId('rag')}>
                What is RAG, and Why Does It Matter?
                </li>
                <li className="link-list links" onClick={()=>scrollToId('architecture')}>
                Architecture Overview
                </li>
                <li className="link-list links" onClick={()=>scrollToId('application-working')}>
                How the Application Works
                </li>
                <li className="link-list links" onClick={()=>scrollToId('components')}>
                Deep Dive into Components
                </li>
                <li className="link-list links" onClick={()=>scrollToId('vipas-predict')}>
                Understanding the VipasSDK predict Method
                </li>
                <li className="link-list links" onClick={()=>scrollToId('deploying-monetizing')}>
                Deploying and Monetizing the App on Vipas.AI
                </li>
                <li className="link-list links" onClick={()=>scrollToId('conclusion')}>
                Conclusion
                </li> 
                <li className="link-list links" onClick={()=>scrollToId('why-vipas')}>
                Why Monetizing Your AI on Vipas.AI Can Be Life-Changing
                </li>

            </ul>
                </div>
            </div>
        </>
    );
}

export default RagBasedStreamlitApp;
