import React from 'react'
import downloadIcon from "../../../assets/images/download.png";
import Navigation from '../../Navigation';

function TensorFlowImageClassification() {
  return (
    <>
    <div className="flex my-8 relative">
               <div className="w-[100%] xl:w-[66.66%] px-[24px]">
                   <h1 className="mb-3 heading">TensorFlow-based image classification model using the CIFAR-10 dataset</h1>
                  
                   <div className='border rounded-[5px] w-[250px] p-2 shadow-md my-2 hover:bg-gray-100'>
              <a href="https://utils.vipas.ai/vps-ipynb/cifar-10-tf/cifar-10-tf.zip" target="_blank" className='ml-3 flex items-center'><img src={downloadIcon} width={32} height={32} alt="text-summarization-download"/> <span className='ml-2'>Download Notebook</span></a>
              </div>
              <div className='my-4'>
                  <h2>This model is an <strong>end-to-end solution for building, training, and deploying an interactive TensorFlow-based image classification model</strong> using the CIFAR-10 dataset. It includes widgets and output displays for guiding users through each step of the pipeline, from dataset preparation to deploying the model with the <strong>Vipas SDK</strong>.</h2>
                  <p>The pipeline is divided into the following stages:</p>
              </div>
              <ol class="list-decimal ml-6">
                <li>
      <h2 class="font-semibold mb-2">Dataset Loading</h2>
      <ul class="list-disc list-inside">
        <li>Uses TensorFlow to load the CIFAR-10 dataset, providing an overview of the dataset statistics and class names. Includes a button to trigger loading.</li>
      </ul>
      </li>

    <li >
      <h2 class="font-semibold mb-2">Data Preprocessing</h2>
      <ul class="list-disc list-inside ">
        <li>Normalizes image pixel values and adjusts label formats based on the loss function selection. Includes a dropdown for loss function configuration.</li>
      </ul>
    </li>
    <li>
      <h2 class="font-semibold mb-2">Model Definition</h2>
      <ul class="list-disc list-inside ">
        <li>Creates a CNN model with customizable optimizers and loss functions via dropdown widgets. Displays confirmation of model architecture and compilation.</li>
      </ul>
    </li>

    <li class="">
      <h2 class="font-semibold mb-2">Model Training</h2>
      <ul class="list-disc list-inside">
        <li>Trains the model using user-configurable epochs and batch sizes, with real-time output of accuracy and loss metrics.</li>
      </ul>
    </li>

    <li >
      <h2 class="font-semibold mb-2">Model Saving</h2>
      <ul class="list-disc list-inside">
        <li>Saves the trained model in TensorFlow's SavedModel format for future use and deployment.</li>
      </ul>
    </li>

    <li>
      <h2 class="font-semibold mb-2">Prediction</h2>
      <ul class="list-disc list-inside">
        <li>Allows predictions using random test images from CIFAR-10. Displays input images and predicted/true labels.</li>
      </ul>
    </li>
    <li>
      <h2 class="font-semibold mb-2">Model Publishing</h2>
      <ul class="list-disc list-inside">
        <li>Publishes the trained model to the Vipas platform, linking the model folder and processor image for deployment.</li>
      </ul>
    </li>
    <li>
      <h2 class="font-semibold mb-2">Image Upload and Prediction</h2>
      <ul class="list-disc list-inside">
        <li>Accepts user-uploaded images, processes them in base64 format, and provides predictions via the Vipas API.</li>
      </ul>
    </li>
    </ol> 
    <div className='my-4'>
                  <h3 className='font-semibold text-xl'>
                  Steps to Run the Notebook
                  </h3>
                  <ol className='list-decimal ml-6'>
                    <li> <strong>Download the Notebook</strong>
                        <ul className='list-disc ml-3'>
                          <li>Obtain the notebook file and its associated resources as a zip file.</li>
                        </ul>
                    </li>

                    <li> <strong>Extract the Zip</strong>
                        <ul className='list-disc ml-3'>
                          <li>Extract the contents of the zip file using any file extraction tool (e.g., WinRAR, 7-Zip, or the built-in extraction tool in your operating system).</li>
                        </ul>
                    </li>

                    <li> <strong>Upload Files to Google Colab</strong>
                        <ul className='list-disc ml-3'>
                          <li>Open <a href="https://colab.research.google.com/" target="_blank">Google Colab</a> in your browser and sign in to your account.</li>
                          <li>In "File" menu tab, click on "Upload notebook button" and select "upload".</li>
                          <li>Upload all the extracted files, including the <code>.ipynb</code> notebook file, to the Colab environment.</li>
                        </ul>
                    </li>
                    <li> <strong>Run the Notebook</strong>
                        <ul className='list-disc ml-3'>
                          <li>Open the uploaded <code>.ipynb</code> notebook file in Google Colab.</li>
                          <li>Ensure all dependencies (like PyTorch, torchvision, etc.) are installed by running the corresponding cells. If any libraries are missing, you may need to install them using <code>!pip install</code>.</li>
                          <li>Run all the cells sequentially or click on <code>Runtime</code> and <code>Run all</code> to execute the entire notebook.</li>
                        </ul>
                    </li>
                    <li> <strong>Interact with the Notebook</strong>
                        <ul className='list-disc ml-3'>
                          <li>Follow any instructions provided in the notebook, such as selecting hyperparameters or running specific interactive widgets.</li>
                          <li>View the outputs, logs, and predictions as the notebook executes.</li>
                        </ul>
                    </li>
                  </ol>
              </div>  
            
              <Navigation
                       previous={"Previous: Movie Recommendation System Training and Deployment"} previousNav={"movie-recommendation"}
                       next={"Next: Text Summarization"}
                       nextNav={"text-summarization"}
                   />  
               </div>
               
               <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16">
                   {/* <p className="font-semibold my-8 ">On this page</p>
                   <ul className="border-t-2 py-4">
                       <li className="link-list ">
                           <a href="#supported-file-types" className="links">
                               Supported File Types
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#directory-structure" className="links">
                               Directory Structure
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#model-configuration" className="links">
                               Model Configuration
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#summary-workflow" className="links">
                               Summary Workflow
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#examples" className="links">
                               Examples
                           </a>
                       </li>
                   </ul> */}
               </div>
           </div>
   </>
  )
}

export default TensorFlowImageClassification
