import React from 'react'
import downloadIcon from "../../../assets/images/download.png";
import Navigation from '../../Navigation';

function TextSummerization() {
  return (
    <>
    <div className="flex my-8 relative">
               <div className="w-[100%] xl:w-[66.66%] px-[24px]">
                   <h1 className="mb-3 heading">Text Summarization</h1>
                   <div className='border rounded-[5px] w-[250px] p-2 shadow-md my-2 hover:bg-gray-100'>
                <a href="https://utils.vipas.ai/vps-ipynb/seq2seq_text_summariser/text_summariser.zip" target="_blank" className='ml-3 flex items-center'><img src={downloadIcon} width={32} height={32} alt="text-summarization-download" /> <span className='ml-2'>Download Notebook</span></a>
              </div>
              <div className='my-4'>
                <h2><strong>Text Summarization using PyTorch</strong> involves developing a deep learning model to generate concise summaries of long texts while preserving the essential meaning.</h2>
                <p>This guide explains the process of implementing a text summarization model using PyTorch, leveraging a sequence-to-sequence (Seq2Seq) architecture with an encoder-decoder model. Key steps include:</p>
              </div>

              <ol className="list-decimal ml-6">
                <li>
                  <h2 className="font-semibold mb-2">Install Required Libraries</h2>
                  <ul className="list-disc list-inside">
                    <li>Necessary libraries such as PyTorch, Hugging Face datasets, and NLTK are installed to facilitate model training, data handling, and deployment.</li>
                  </ul>
                </li>

                <li >
                  <h2 className="font-semibold mb-2">Load and Prepare Data</h2>
                  <ul className="list-disc list-inside ">
                    <li>The CNN/Daily Mail dataset is loaded for summarization tasks, focusing on extracting articles and their corresponding summaries.</li>
                  </ul>
                </li>

                <li >
                  <h2 className="font-semibold mb-2">Tokenization and Vocabulary Building</h2>
                  <ul className="list-disc list-inside ">
                    <li>Texts are tokenized into words, and vocabularies for the input text and summaries are constructed. Special tokens for padding and sequence markers are added.</li>
                  </ul>
                </li>

                <li>
                  <h2 className="font-semibold mb-2">Data Preprocessing</h2>
                  <ul className="list-disc list-inside ">
                    <li>Sequences are padded or truncated to fixed lengths to ensure uniformity. The data is then prepared as PyTorch datasets for training.</li>
                  </ul>
                </li>

                <li className="mb-6">
                  <h2 className="font-semibold mb-2">Model Architecture</h2>
                  <ul className="list-disc list-inside">
                    <li>An encoder-decoder architecture is built using LSTM layers. The encoder processes input sequences into context vectors, while the decoder generates summaries using these vectors.</li>
                  </ul>
                </li>

                <li >
                  <h2 className="font-semibold mb-2">Training</h2>
                  <ul className="list-disc list-inside">
                    <li>The model is trained using a custom training loop that calculates loss, updates weights, and validates performance over epochs.</li>
                  </ul>
                </li>

                <li>
                  <h2 className="font-semibold mb-2">Inference</h2>
                  <ul className="list-disc list-inside">
                    <li>The trained model is used to generate summaries for input text by decoding sequences in inference mode.</li>
                  </ul>
                </li>
                <li>
                  <h2 className="font-semibold mb-2">Model Saving and Deployment</h2>
                  <ul className="list-disc list-inside">
                    <li>The trained model and vocabularies are saved, packaged into a deployable format (.mar), and published to platforms like Vipas.AI.</li>
                  </ul>
                </li>
                <li>
                  <h2 className="font-semibold mb-2">Prediction</h2>
                  <ul className="list-disc list-inside">
                    <li>Using Vipas.AI, the published model is invoked to generate summaries for new text inputs, showcasing real-world applications of abstractive summarization.</li>
                  </ul>
                </li>
              </ol>
              <p className='my-2'>This implementation combines NLP preprocessing techniques, deep learning, and deployment strategies to build a scalable text summarization solution.</p>
              <div className='my-4'>
                  <h3 className='font-semibold text-xl'>
                  Steps to Run the Notebook
                  </h3>
                  <ol className='list-decimal ml-6'>
                    <li> <strong>Download the Notebook</strong>
                        <ul className='list-disc ml-3'>
                          <li>Obtain the notebook file and its associated resources as a zip file.</li>
                        </ul>
                    </li>

                    <li> <strong>Extract the Zip</strong>
                        <ul className='list-disc ml-3'>
                          <li>Extract the contents of the zip file using any file extraction tool (e.g., WinRAR, 7-Zip, or the built-in extraction tool in your operating system).</li>
                        </ul>
                    </li>

                    <li> <strong>Upload Files to Google Colab</strong>
                        <ul className='list-disc ml-3'>
                          <li>Open <a href="https://colab.research.google.com/" target="_blank">Google Colab</a> in your browser and sign in to your account.</li>
                          <li>In "File" menu tab, click on "Upload notebook button" and select "upload".</li>
                          <li>Upload all the extracted files, including the <code>.ipynb</code> notebook file, to the Colab environment.</li>
                        </ul>
                    </li>
                    <li> <strong>Run the Notebook</strong>
                        <ul className='list-disc ml-3'>
                          <li>Open the uploaded <code>.ipynb</code> notebook file in Google Colab.</li>
                          <li>Ensure all dependencies (like PyTorch, torchvision, etc.) are installed by running the corresponding cells. If any libraries are missing, you may need to install them using <code>!pip install</code>.</li>
                          <li>Run all the cells sequentially or click on <code>Runtime</code> and <code>Run all</code> to execute the entire notebook.</li>
                        </ul>
                    </li>
                    <li> <strong>Interact with the Notebook</strong>
                        <ul className='list-disc ml-3'>
                          <li>Follow any instructions provided in the notebook, such as selecting hyperparameters or running specific interactive widgets.</li>
                          <li>View the outputs, logs, and predictions as the notebook executes.</li>
                        </ul>
                    </li>
                  </ol>
              </div>
              <Navigation
                       previous={"Previous: TensorFlow-based image classification model using the CIFAR-10 dataset"} previousNav={"tensorflow-image-classification"}
                       next={"Next: Flower Class Prediction"}
                       nextNav={"flower-class-predication"}
                   />  
               </div>
               <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16">
                   {/* <p className="font-semibold my-8 ">On this page</p>
                   <ul className="border-t-2 py-4">
                       <li className="link-list ">
                           <a href="#supported-file-types" className="links">
                               Supported File Types
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#directory-structure" className="links">
                               Directory Structure
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#model-configuration" className="links">
                               Model Configuration
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#summary-workflow" className="links">
                               Summary Workflow
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#examples" className="links">
                               Examples
                           </a>
                       </li>
                   </ul> */}
               </div>
           </div>
   </>
  )
}

export default TextSummerization
