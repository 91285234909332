import React from 'react'
import downloadIcon from "../../../assets/images/download.png";
import Navigation from '../../Navigation';

function DigitRecognition() {
  return (
    <>
    <div className="flex my-8 relative">
               <div className="w-[100%] xl:w-[66.66%] px-[24px]">
                   <h1 className="mb-3 heading">Digit Recognition</h1>
                   <div className='border rounded-[5px] w-[250px] p-2 shadow-md my-2 hover:bg-gray-100'>
              <a href="https://utils.vipas.ai/vps-ipynb/digit_recogniser/digit_recognsier.zip" target="_blank" className='ml-3 flex items-center'><img src={downloadIcon} width={32} height={32} alt="text-summarization-download"/> <span className='ml-2'>Download Notebook</span></a>
              </div>
              <div className='my-4'>
                  <h2><strong>Digit recognition</strong> is a classification problem where the goal is to identify handwritten digits (0-9) from images. Using Sklearn, this can be implemented by leveraging the <strong>Digits Dataset</strong> from <code>sklearn.datasets</code>. This project leverages Sklearn and the Vipas SDK to create a machine learning pipeline for digit recognition, using the MNIST dataset. The pipeline involves:</h2>
              </div>
              <ol class="list-decimal ml-6">
                <li>
      <h2 class="font-semibold mb-2">Dataset Handling</h2>
      <ul class="list-disc list-inside">
        <li>Downloads and preprocesses the MNIST dataset, resizing images and splitting data into training and testing sets based on user-defined parameters.</li>
      </ul>
      </li>

    <li >
      <h2 class="font-semibold mb-2">Interactive User Inputs</h2>
      <ul class="list-disc list-inside ">
        <li>Customizes training parameters like the number of estimators, random state, image size, and train-test split ratio through an interactive UI.</li>
      </ul>
    </li>
    <li>
      <h2 class="font-semibold mb-2">Model Training</h2>
      <ul class="list-disc list-inside ">
        <li>Implements a Random Forest classifier to train on the preprocessed data, evaluating its performance using accuracy scores and classification reports.</li>
      </ul>
    </li>

    <li class="mb-6">
      <h2 class="font-semibold mb-2">Model Saving and Deployment</h2>
      <ul class="list-disc list-inside">
        <li>Saves the trained model and preprocessing script, which are uploaded to the Vipas.AI platform for deployment.</li>
      </ul>
    </li>

    <li >
      <h2 class="font-semibold mb-2">Image Preparation</h2>
      <ul class="list-disc list-inside">
        <li>Downloads an image, converts it to Base64 format, and preprocesses it for prediction.</li>
      </ul>
    </li>

    <li>
      <h2 class="font-semibold mb-2">Model Prediction</h2>
      <ul class="list-disc list-inside">
        <li>Utilizes the deployed model to make predictions on input images and retrieves results in real-time.</li>
      </ul>
    </li>
    <li>
      <h2 class="font-semibold mb-2">Publishing and Integration</h2>
      <ul class="list-disc list-inside">
        <li>Integrates the model with Vipas.AI, enabling public accessibility and seamless deployment for real-world applications.</li>
      </ul>
    </li>
    </ol> 
    <div className='my-4'>
                  <h3 className='font-semibold text-xl'>
                  Steps to Run the Notebook
                  </h3>
                  <ol className='list-decimal ml-6'>
                    <li> <strong>Download the Notebook</strong>
                        <ul className='list-disc ml-3'>
                          <li>Obtain the notebook file and its associated resources as a zip file.</li>
                        </ul>
                    </li>

                    <li> <strong>Extract the Zip</strong>
                        <ul className='list-disc ml-3'>
                          <li>Extract the contents of the zip file using any file extraction tool (e.g., WinRAR, 7-Zip, or the built-in extraction tool in your operating system).</li>
                        </ul>
                    </li>

                    <li> <strong>Upload Files to Google Colab</strong>
                        <ul className='list-disc ml-3'>
                          <li>Open <a href="https://colab.research.google.com/" target="_blank">Google Colab</a> in your browser and sign in to your account.</li>
                          <li>In "File" menu tab, click on "Upload notebook button" and select "upload".</li>
                          <li>Upload all the extracted files, including the <code>.ipynb</code> notebook file, to the Colab environment.</li>
                        </ul>
                    </li>
                    <li> <strong>Run the Notebook</strong>
                        <ul className='list-disc ml-3'>
                          <li>Open the uploaded <code>.ipynb</code> notebook file in Google Colab.</li>
                          <li>Ensure all dependencies (like PyTorch, torchvision, etc.) are installed by running the corresponding cells. If any libraries are missing, you may need to install them using <code>!pip install</code>.</li>
                          <li>Run all the cells sequentially or click on <code>Runtime</code> and <code>Run all</code> to execute the entire notebook.</li>
                        </ul>
                    </li>
                    <li> <strong>Interact with the Notebook</strong>
                        <ul className='list-disc ml-3'>
                          <li>Follow any instructions provided in the notebook, such as selecting hyperparameters or running specific interactive widgets.</li>
                          <li>View the outputs, logs, and predictions as the notebook executes.</li>
                        </ul>
                    </li>
                  </ol>
                  
              </div> 
            
              <Navigation
                       previous={"Previous: Stock Prediction"} previousNav={"stock-prediction"}
                   />  
               </div>
               <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16">
                   {/* <p className="font-semibold my-8 ">On this page</p>
                   <ul className="border-t-2 py-4">
                       <li className="link-list ">
                           <a href="#supported-file-types" className="links">
                               Supported File Types
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#directory-structure" className="links">
                               Directory Structure
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#model-configuration" className="links">
                               Model Configuration
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#summary-workflow" className="links">
                               Summary Workflow
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#examples" className="links">
                               Examples
                           </a>
                       </li>
                   </ul> */}
               </div>
           </div>
   </>
  )
}

export default DigitRecognition
