import React from 'react'
import downloadIcon from "../../../assets/images/download.png";
import Navigation from '../../Navigation';

function WineQualityPrediction() {
  return (
    <>
    <div className="flex my-8 relative">
               <div className="w-[100%] xl:w-[66.66%] px-[24px]">
                   <h1 className="mb-3 heading">Wine Quality Prediction Using ElasticNet Regression</h1>
                  
                   <div className="border rounded-[5px] w-[250px] p-2 shadow-md my-2 hover:bg-gray-100">
                               <a
                                   href="https://utils.vipas.ai/vps-ipynb/ml_flow_sklearn/sklearn.zip"
                                   target="_blank"
                                   className="ml-3 flex items-center"
                               >
                                   <img
                                       src={downloadIcon}
                                       width={32}
                                       height={32}
                                       alt="text-summarization-download"
                                   />{" "}
                                   <span className="ml-2">
                                       Download Notebook
                                   </span>
                               </a>
                           </div>
                           <p className="my-2">
                               This guide explains the implementation of a
                               machine learning pipeline to predict wine
                               quality based on various physicochemical
                               attributes. The process involves data
                               preprocessing, model training, evaluation, and
                               deployment with ElasticNet regression using
                               MLflow for tracking and logging.
                           </p>

                           <p className="text-xl font-semibold my-2">
                               Key Steps:
                           </p>
                           <ol className="list-decimal ml-6">
                               <li>
                                   <h2 className="font-semibold mb-2">
                                       Dataset Overview
                                   </h2>
                                   <p>
                                       The{" "}
                                       <strong>Wine Quality Dataset</strong>{" "}
                                       from the UCI repository is used. This
                                       dataset contains physicochemical
                                       properties (e.g., acidity, sugar, pH) as
                                       features and wine quality scores
                                       (ranging from 3 to 9) as the target
                                       variable.
                                   </p>
                               </li>

                               <li>
                                   <h2 className="font-semibold mb-2">
                                       Data Preprocessing
                                   </h2>
                                   <ul className="list-disc list-inside ">
                                       <li>
                                           <strong>Splitting Data</strong>: The
                                           dataset is split into training (75%)
                                           and testing (25%) subsets.
                                       </li>
                                       <li>
                                           <strong>
                                               Feature and Target Separation
                                           </strong>
                                           : Features are extracted by dropping
                                           the <code>quality</code> column,
                                           while the target variable (
                                           <code>quality</code>) is used for
                                           predictions.
                                       </li>
                                   </ul>
                               </li>
                               <li>
                                   <h2 className="font-semibold mb-2">
                                       Model Selection: ElasticNet Regression
                                   </h2>
                                   <p>
                                       The{" "}
                                       <code>ElasticNet regression model</code>{" "}
                                       is used, which combines L1 (Lasso) and
                                       L2 (Ridge) regularization:
                                   </p>
                                   <ul className="list-disc list-inside">
                                       <li>
                                           <code>alpha</code>: Controls the
                                           regularization strength.
                                       </li>
                                       <li>
                                           <code>l1_ratio</code>: Balances L1
                                           and L2 regularization.
                                       </li>
                                   </ul>
                               </li>

                               <li className="mb-6">
                                   <h2 className="font-semibold mb-2">
                                       Model Training
                                   </h2>
                                   <p>
                                       The ElasticNet model is trained on the
                                       training data using the default
                                       parameters:
                                   </p>
                                   <ul className="list-disc list-inside">
                                       <li>
                                           <code>alpha=0.5</code>
                                       </li>
                                       <li>
                                           <code>l1_ratio=0.5</code>
                                       </li>
                                   </ul>
                               </li>

                               <li>
                                   <h2 className="font-semibold mb-2">
                                       Model Evaluation
                                   </h2>
                                   <p>
                                       Key evaluation metrics calculated on the
                                       test dataset include:
                                   </p>
                                   <ul className="list-disc list-inside">
                                       <li>
                                           <strong>
                                               Root Mean Squared Error (RMSE)
                                           </strong>
                                           : Measures prediction error.
                                       </li>
                                       <li>
                                           <strong>
                                               Mean Absolute Error (MAE)
                                           </strong>
                                           : Measures average magnitude of
                                           errors.
                                       </li>
                                       <li>
                                           <strong>R-squared (R²)</strong>:
                                           Explains variance captured by the
                                           model.
                                       </li>
                                   </ul>
                               </li>

                               <li>
                                   <h2 className="font-semibold mb-2">
                                       Logging with MLflow
                                   </h2>
                                   <p>MLflow is used to log the following:</p>
                                   <ul className="list-disc list-inside">
                                       <li>
                                           <strong>Parameters</strong>:{" "}
                                           <code>alpha</code> and{" "}
                                           <code>l1_ratio.</code>
                                       </li>
                                       <li>
                                           <strong>Metrics</strong>: RMSE, MAE,
                                           and R² scores.
                                       </li>
                                       <li>
                                           <strong>Model</strong>: The trained
                                           ElasticNet model is logged and
                                           stored.
                                       </li>
                                   </ul>
                               </li>
                               <li>
                                   <h2 className="font-semibold mb-2">
                                       Deployment Configuration
                                   </h2>
                                   <p>
                                       After training, the model is prepared
                                       for deployment using:
                                   </p>
                                   <ul className="list-disc list-inside">
                                       <li>
                                           <strong>
                                               MLServer Configuration
                                           </strong>
                                           : Defines the model runtime and
                                           parameters.
                                       </li>
                                       <li>
                                           <strong>Dockerfile</strong>: Creates
                                           a Docker image for deploying the
                                           model with MLServer.
                                       </li>
                                   </ul>
                               </li>
                               <li>
                                   <h2 className="font-semibold mb-2">
                                       Prediction
                                   </h2>
                                   <p>
                                       The model takes physicochemical
                                       attributes as inputs to predict wine
                                       quality scores. For example:
                                   </p>
                                   <ul className="list-disc list-inside">
                                       <li>
                                           Inputs include features like{" "}
                                           <code>fixed acidity</code>,{" "}
                                           <code>volatile acidity</code>,{" "}
                                           <code>citric acid</code>, etc.
                                       </li>
                                       <li>
                                           The model outputs a predicted
                                           quality score.
                                       </li>
                                   </ul>
                               </li>
                           </ol>
                           <p className="font-semibold">Summary</p>
                           <p className='my-2'>
                               This implementation demonstrates how to build a
                               regression model for predicting wine quality,
                               focusing on feature engineering, model
                               evaluation, and logging for reproducibility. The
                               pipeline integrates ElasticNet regression and
                               MLflow for efficient experimentation and
                               deployment on Vipas.AI.
                           </p>
                           <Navigation
                       previous={"Previous: Deploying a PyTorch model on Vipas.AI"} previousNav={"pytorch-model-with-mlflow"}
                       next={"Next: TensorFlow Model Training and Deployment"}
                       nextNav={"tensorflow-with-mlflow"}
                   />
               </div>
               <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16">
                   {/* <p className="font-semibold my-8 ">On this page</p>
                   <ul className="border-t-2 py-4">
                       <li className="link-list ">
                           <a href="#supported-file-types" className="links">
                               Supported File Types
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#directory-structure" className="links">
                               Directory Structure
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#model-configuration" className="links">
                               Model Configuration
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#summary-workflow" className="links">
                               Summary Workflow
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#examples" className="links">
                               Examples
                           </a>
                       </li>
                   </ul> */}
               </div>
           </div>
   </>
  )
}

export default WineQualityPrediction
