import React from 'react'
import downloadIcon from "../../../assets/images/download.png";
import Navigation from '../../Navigation';

function StockPrediction() {
  return (
    <>
    <div className="flex my-8 relative">
               <div className="w-[100%] xl:w-[66.66%] px-[24px]">
                   <h1 className="mb-3 heading">Stock Prediction</h1>
                   <div className='border rounded-[5px] w-[250px] p-2 shadow-md my-2 hover:bg-gray-100'>
                <a href="https://utils.vipas.ai/vps-ipynb/stock_prediction/stock_prediction.zip" target="_blank" className='ml-3 flex items-center'><img src={downloadIcon} width={32} height={32} alt="text-summarization-download" /> <span className='ml-2'>Download Notebook</span></a>
              </div>
              <div className='my-4'>
                <h2><strong>Stock Prediction using PyTorch</strong> involves leveraging deep learning to forecast stock prices or trends based on historical data. PyTorch, a popular deep learning framework, provides the flexibility to create and train models efficiently.</h2>
                <p>This interactive project demonstrates stock price prediction using PyTorch's LSTM (Long Short-Term Memory) neural network model. The workflow is as follows:</p>
              </div>

              <ol class="list-decimal ml-6">
                <li>
                  <h2 class="font-semibold mb-2">Data Preparation</h2>
                  <ul class="list-disc list-inside">
                    <li>Historical stock data is fetched using yfinance, and the closing prices are preprocessed and scaled with MinMaxScaler. The data is split into training and testing sets, and sequences of stock prices are prepared for the LSTM model.</li>
                  </ul>
                </li>

                <li >
                  <h2 class="font-semibold mb-2">Model Design</h2>
                  <ul class="list-disc list-inside ">
                    <li>A custom LSTM model is implemented, consisting of an LSTM layer for handling sequential data and a fully connected layer to output the predicted stock price.</li>
                  </ul>
                </li>
                <li>
                  <h2 class="font-semibold mb-2">Training</h2>
                  <ul class="list-disc list-inside ">
                    <li>The model is trained using historical data, optimizing it using selected loss functions (e.g., MSELoss) and optimizers (e.g., Adam or SGD). Training involves minimizing the prediction error over several epochs.</li>
                  </ul>
                </li>

                <li class="mb-6">
                  <h2 class="font-semibold mb-2">Prediction</h2>
                  <ul class="list-disc list-inside">
                    <li>After training, the model predicts future stock prices using the most recent data points. The predictions are scaled back to their original range for meaningful interpretation.</li>
                  </ul>
                </li>

                <li >
                  <h2 class="font-semibold mb-2">Interactivity</h2>
                  <ul class="list-disc list-inside">
                    <li>The project features interactive widgets for selecting stock tickers, date ranges, model parameters (e.g., sequence length, hidden size), and training or predicting prices. Users can also save and archive the model for future use or deployment.</li>
                  </ul>
                </li>

                <li>
                  <h2 class="font-semibold mb-2">Model Deployment</h2>
                  <ul class="list-disc list-inside">
                    <li> Using the VIPAS SDK, the trained model can be managed, published, and used for real-time predictions. The SDK provides tools to upload the model, handle project permissions, and make predictions via APIs.</li>
                  </ul>
                </li>
                <li>
                  <h2 class="font-semibold mb-2">Visualization</h2>
                  <ul class="list-disc list-inside">
                    <li> Stock prices and predictions are visualized using Matplotlib for better interpretation and analysis.</li>
                  </ul>
                </li>
              </ol>
              <div className='my-4'>
                  <h3 className='font-semibold text-xl'>
                  Steps to Run the Notebook 
                  </h3>
                  <ol className='list-decimal ml-6'>
                    <li> <strong>Download the Notebook</strong>
                        <ul className='list-disc ml-3'>
                          <li>Obtain the notebook file and its associated resources as a zip file.</li>
                        </ul>
                    </li>

                    <li> <strong>Extract the Zip</strong>
                        <ul className='list-disc ml-3'>
                          <li>Extract the contents of the zip file using any file extraction tool (e.g., WinRAR, 7-Zip, or the built-in extraction tool in your operating system).</li>
                        </ul>
                    </li>

                    <li> <strong>Upload Files to Google Colab</strong>
                        <ul className='list-disc ml-3'>
                          <li>Open <a href="https://colab.research.google.com/" target="_blank">Google Colab</a> in your browser and sign in to your account.</li>
                          <li>In "File" menu tab, click on "Upload notebook button" and select "upload".</li>
                          <li>Upload all the extracted files, including the <code>.ipynb</code> notebook file, to the Colab environment.</li>
                        </ul>
                    </li>
                    <li> <strong>Run the Notebook</strong>
                        <ul className='list-disc ml-3'>
                          <li>Open the uploaded <code>.ipynb</code> notebook file in Google Colab.</li>
                          <li>Ensure all dependencies (like PyTorch, torchvision, etc.) are installed by running the corresponding cells. If any libraries are missing, you may need to install them using <code>!pip install</code>.</li>
                          <li>Run all the cells sequentially or click on <code>Runtime</code> and <code>Run all</code> to execute the entire notebook.</li>
                        </ul>
                    </li>
                    <li> <strong>Interact with the Notebook</strong>
                        <ul className='list-disc ml-3'>
                          <li>Follow any instructions provided in the notebook, such as selecting hyperparameters or running specific interactive widgets.</li>
                          <li>View the outputs, logs, and predictions as the notebook executes.</li>
                        </ul>
                    </li>
                  </ol>
              </div>
              <Navigation
                       previous={"Previous: Flower Class Prediction"} previousNav={"flower-class-predication"}
                       next={"Next: Digit Recognition"}
                       nextNav={"digit-recognition"}
                   />  
               </div>
               <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16">
                   {/* <p className="font-semibold my-8 ">On this page</p>
                   <ul className="border-t-2 py-4">
                       <li className="link-list ">
                           <a href="#supported-file-types" className="links">
                               Supported File Types
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#directory-structure" className="links">
                               Directory Structure
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#model-configuration" className="links">
                               Model Configuration
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#summary-workflow" className="links">
                               Summary Workflow
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#examples" className="links">
                               Examples
                           </a>
                       </li>
                   </ul> */}
               </div>
           </div>
   </>
  )
}

export default StockPrediction
