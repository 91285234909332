import React from 'react'
import downloadIcon from "../../../assets/images/download.png";
import { CopyBlock,anOldHope } from 'react-code-blocks';
import Navigation from '../../Navigation';

function MovieRecommendation() {
    const codeSnippets = {
        tensorflow: `import mlflow.tensorflow
import tensorflow as tf

model = tf.keras.Sequential([
    tf.keras.layers.Dense(10, activation='relu'),
    tf.keras.layers.Dense(1, activation='sigmoid')
])
model.compile(optimizer='adam', loss='binary_crossentropy')
model.fit([[0.1], [0.2], [0.3]], [[1], [0], [1]], epochs=10)

mlflow.tensorflow.log_model(model, "tensorflow_model")`,
        pytorch: `import mlflow.pytorch
import torch.nn as nn

class SimpleModel(nn.Module):
    def __init__(self):
        super(SimpleModel, self).__init__()
        self.fc = nn.Linear(10, 1)

    def forward(self, x):
        return self.fc(x)

model = SimpleModel()
mlflow.pytorch.log_model(model, "pytorch_model")`,
        xgboost: `import mlflow.xgboost
import xgboost as xgb
import numpy as np

dtrain = xgb.DMatrix(np.random.rand(100, 10), label=np.random.randint(2, size=100))
model = xgb.train({"objective": "binary:logistic"}, dtrain, num_boost_round=10)

mlflow.xgboost.log_model(model, "xgboost_model")`,
        sklearn: `import mlflow.sklearn
from sklearn.linear_model import LogisticRegression

model = LogisticRegression()
model.fit([[0, 1], [1, 0]], [0, 1])

mlflow.sklearn.log_model(model, "sklearn_model")`,
        onnx: `import mlflow.onnx

onnx_model_path = "model.onnx"
mlflow.onnx.log_model(onnx_model_path, "onnx_model")`,
        vipasSDK: `from vipas.mlflow import ModelClient
# Fetch configuration files
config_files_path = ModelClient.mlflow_configuration_files(
    model_id="your-model-id",  # Unique identifier for your model
    custom_runtime=False      # Set to True for custom runtime configurations
)
    
print(f"Configuration files saved at: {config_files_path}")`,
        defaultDocker: `# Use a Python base image
FROM python:3.10.12
# Set the working directory
WORKDIR /app
# Copy all necessary files
COPY model-settings.json /app/model-settings.json
COPY model/ /app/model
COPY model/requirements.txt /app/model/requirements.txt
# Install required Python packages
RUN pip install --no-cache-dir -r /app/model/requirements.txt
RUN pip install --no-cache-dir mlserver-mlflow mlserver
EXPOSE 8080
CMD ["mlserver", "start", "/app"]
`,
        defaultModelSettings: `
{
  "name": "<model-service-name>",//provided by vipas
  "implementation": "mlserver_mlflow.MLflowRuntime",
  "parameters": {{
      "uri": "/app/model"
  }}
}`,
        customModelSetting: `
{
  "name": "<model-service-name>",  // Provided by Vipas.AI
  "implementation": "custom_runtime.CustomMLflowRuntime",
  "parameters": {
      "uri": "/app/model"
  }
}`,
        customRuntime: `
from mlserver_mlflow import MLflowRuntime
import pandas as pd
from mlserver.types import ResponseOutput, InferenceResponse

class CustomMLflowRuntime(MLflowRuntime):
    async def predict(self, payload):
        # Decode the input payload
        decoded_payload = self.decode_request(payload)

        # Access the input data from the InferenceRequest object
        input_data = decoded_payload.inputs[0].data

        # Convert the input data to a pandas DataFrame
        input_data = pd.DataFrame([input_data])  # Wrap data in a list to create a single row DataFrame

        # Debug: Print the converted DataFrame
        print("Input DataFrame:", input_data)

        # Perform inference using the model
        model_output = self._model.predict(input_data)

        # Debug: Print the model output
        print("Model Output:", model_output)

        # Convert the model output to a list (use NumPy's tolist if necessary)
        model_output_list = model_output.values.tolist() if isinstance(model_output, pd.DataFrame) else model_output.tolist()

        # Prepare the response (ensure the "name" field is a string)
        response_output = ResponseOutput(
            name="predict",  # Set a valid string for the output name
            shape=model_output.shape if hasattr(model_output, "shape") else [len(model_output_list)],
            datatype="FP32",  # Adjust datatype as needed
            data=model_output_list  # Convert output to a list
        )

        # Return the properly formatted inference response
        return InferenceResponse(model_name=self.name, outputs=[response_output])`,
        customRuntimeDocker: `
# Use a Python base image
FROM python:3.10.12
# Set the working directory
WORKDIR /app
# Copy all necessary files
COPY model-settings.json /app/model-settings.json
COPY model/ /app/model
COPY model/requirements.txt /app/model/requirements.txt
COPY custom_runtime.py /app/custom_runtime.py
# Install required Python packages
RUN pip install --no-cache-dir -r /app/model/requirements.txt
RUN pip install --no-cache-dir mlserver-mlflow mlserver
EXPOSE 8080
CMD ["mlserver", "start", "/app"]
`,
        customRuntimeModelSettings: `
{
  "name": "<model-service-name>",//provided by vipas
  "implementation": "custom_runtime.CustomMLflowRuntime",
  "parameters": {{
      "uri": "/app/model"
  }}
}
`,
        packageInstallCommand: `RUN pip install --no-cache-dir <additional-package>`,
        dockerBuild: `docker build -t <docker-username>/<image-name>:<tag> .`,
        dockerPush: `docker push <docker-username>/<image-name>:<tag>`,
        vipasPublish: `from vipas.model import ModelClient

# Publish the model
ModelClient.publish_model(
    model_id="your-model-id",  # Replace with your model's unique ID
    mlflow_config_path="path-to-your-docker-json-file"  # Path to the Docker image configuration JSON
)`,
        mlflowConfigPath: `{
  "docker_image": "<docker-username>/<image-name>:<tag>",
  "docker_token": "docker_pat_token"
}`,
        movieRecommendInput: `{
    "inputs": [
        {
            "name": "user_id",
            "shape": [1],
            "datatype": "INT32",
            "data": [3]
        }
    ]
}`,
    };
  return (
    <>
    <div className="flex my-8 relative">
               <div className="w-[100%] xl:w-[66.66%] px-[24px]">
                   <h1 className="mb-3 heading">Movie Recommendation System Training and Deployment</h1>
                   <div className="border rounded-[5px] w-[250px] p-2 shadow-md my-2 hover:bg-gray-100">
                                <a
                                    href="https://utils.vipas.ai/vps-ipynb/ml_flow_tensorflow/movie_recomendation_system.zip"
                                    target="_blank"
                                    className="ml-3 flex items-center"
                                >
                                    <img
                                        src={downloadIcon}
                                        width={32}
                                        height={32}
                                        alt="text-summarization-download"
                                    />{" "}
                                    <span className="ml-2">
                                        Download Notebook
                                    </span>
                                </a>
                            </div>
                            <p className="my-2">
                                This guide provides a comprehensive workflow for
                                training, logging, and deploying a
                                TensorFlow-based recommendation model using
                                MLflow, Docker, and the Vipas SDK. The model
                                predicts movie ratings based on user and movie
                                IDs, leveraging embedding layers for feature
                                extraction and dense layers for regression.
                            </p>

                            <p className="text-xl font-semibold my-2">
                                Key Steps:
                            </p>
                            <ol className="list-decimal ml-6">
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Dataset Download and Loading
                                    </h2>
                                    <ul className="list-disc list-inside ">
                                        <li>
                                            <strong>Dataset Source</strong>:{" "}
                                            <a
                                                href="https://mlflow.org/docs/latest/models.html"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="links !text-[16px]"
                                            >
                                                GroupLens MovieLens Dataset
                                            </a>
                                        </li>
                                        <li>
                                            <strong>Description</strong>:
                                            Ratings file (
                                            <code>ratings.csv</code>) is used,
                                            containing:
                                            <ul className="list-disc list-inside">
                                                <li>
                                                    <code>userId</code>
                                                </li>
                                                <li>
                                                    <code>movieId</code>
                                                </li>
                                                <li>
                                                    <code>rating</code>
                                                </li>
                                                <li>
                                                    <code>timestamp</code>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Steps</strong>:
                                            <ul className="list-disc list-inside">
                                                <li>
                                                    Download and extract the
                                                    dataset.
                                                </li>
                                                <li>
                                                    Load{" "}
                                                    <code>ratings.csv</code>{" "}
                                                    into a pandas DataFrame.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Data Preprocessing
                                    </h2>
                                    <ul className="list-disc list-inside ">
                                        <li>
                                            <strong>Description</strong>:
                                            <ul className="list-decimal list-inside">
                                                <li>
                                                    Filter to include the first
                                                    100 unique movie IDs.
                                                </li>
                                                <li>
                                                    Encode <code>userId</code>{" "}
                                                    and <code>movieId</code> as
                                                    integers for model
                                                    compatibility.
                                                </li>
                                                <li>
                                                    Preprocess ratings to
                                                    normalize input.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Libraries Used</strong>:{" "}
                                            <code>pandas</code>,{" "}
                                            <code>LabelEncoder</code> from{" "}
                                            <code>sklearn</code>,{" "}
                                            <code>numpy</code>.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Model Definition
                                    </h2>

                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>Architecture</strong>:
                                            <ul className="list-disc list-inside">
                                                <li>
                                                    <strong>Inputs</strong>:
                                                    <ul className="list-disc list-inside">
                                                        <li>User ID</li>
                                                        <li>Movie ID</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <strong>Layers</strong>:
                                                    <ul className="list-disc list-inside">
                                                        <li>
                                                            Embedding layers for
                                                            users and movies.
                                                        </li>
                                                        <li>
                                                            Flatten and
                                                            concatenate
                                                            embeddings.
                                                        </li>
                                                        <li>
                                                            Fully connected
                                                            layers with ReLU
                                                            activation.
                                                        </li>
                                                        <li>
                                                            Output layer with a
                                                            linear activation
                                                            function for
                                                            regression.
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <strong>Parameters</strong>:
                                                    <ul className="list-disc list-inside">
                                                        <li>
                                                            <strong>
                                                                Embedding
                                                                Dimension
                                                            </strong>
                                                            :User-defined.
                                                        </li>
                                                        <li>
                                                            <strong>
                                                                Learning Rate
                                                            </strong>
                                                            : User-defined.
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Framework</strong>:
                                            TensorFlow with Keras API.
                                        </li>
                                    </ul>
                                </li>

                                <li className="mb-6">
                                    <h2 className="font-semibold mb-2">
                                        Model Training and Logging with MLflow
                                    </h2>

                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>Steps</strong>:
                                            <ol className="list-decimal list-inside">
                                                <li>
                                                    Split data into training and
                                                    testing sets using{" "}
                                                    <code>
                                                        train_test_split
                                                    </code>
                                                    .
                                                </li>
                                                <li>
                                                    Enable MLflow autologging
                                                    with{" "}
                                                    <code>
                                                        mlflow.keras.autolog()
                                                    </code>
                                                    .
                                                </li>
                                                <li>
                                                    Train the model for
                                                    user-defined epochs and
                                                    batch size.
                                                </li>
                                                <li>
                                                    Save the trained model (
                                                    <code>model.h5</code>) and
                                                    log it to MLflow.
                                                </li>
                                                <li>
                                                    Log additional artifacts:
                                                    <ul className="list-disc list-inside">
                                                        <li>
                                                            User and movie
                                                            encoders.
                                                        </li>
                                                        <li>
                                                            Movie ID to title
                                                            mapping.
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <strong>Artifacts Directory</strong>
                                            :{" "}
                                            <code>
                                                mlruns/0/&lt;run_id&gt;/artifacts/
                                            </code>
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Deployment Configuration
                                    </h2>

                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>Steps</strong>:{" "}
                                            <ol className="list-decimal list-inside">
                                                <li>
                                                    Generate MLServer
                                                    configuration using Vipas
                                                    SDK:
                                                    <ul className="list-disc list-inside">
                                                        <li>
                                                            Fetch runtime and
                                                            deployment files.
                                                        </li>
                                                        <li>
                                                            Include necessary
                                                            artifacts like
                                                            encoders and mapping
                                                            files.
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    Store configuration in{" "}
                                                    <code>
                                                        mlflow_config.json
                                                    </code>
                                                    .
                                                </li>
                                            </ol>
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Docker Image Creation and Publishing
                                    </h2>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>Description</strong>:{" "}
                                            Containerize the model for scalable
                                            deployment.
                                        </li>
                                        <li>
                                            <strong>Steps</strong>:
                                            <ul className="list-decimal list-inside">
                                                <li>
                                                    Use the artifacts folder to
                                                    build a Docker image.
                                                </li>
                                                <li>
                                                    Push the image to a Docker
                                                    registry (optional).
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Tools</strong>: Python{" "}
                                            <code>docker</code> library.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Model Publishing with Vipas SDK
                                    </h2>

                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>Description</strong>:
                                            Publish the MLflow model to the
                                            Vipas.AI platform.
                                        </li>
                                        <li>
                                            <strong>Steps</strong>:
                                            <ul className="list-decimal list-inside">
                                                <li>
                                                    Use{" "}
                                                    <code>
                                                        mlflow_config.json
                                                    </code>{" "}
                                                    for deployment
                                                    configurations.
                                                </li>
                                                <li>
                                                    Publish the model using the
                                                    Vipas SDK's{" "}
                                                    <code>publish</code>{" "}
                                                    function.
                                                </li>
                                                <li>
                                                    Retrieve and log the
                                                    response for successful
                                                    deployment.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Model Prediction
                                    </h2>

                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>Workflow</strong>
                                            <ul className="list-decimal list-inside">
                                                <li>
                                                    Accept JSON input containing
                                                    user and movie IDs.
                                                </li>
                                                <li>
                                                    Perform inference using the
                                                    deployed model.
                                                </li>
                                                <li>
                                                    Return predicted ratings as
                                                    output.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                            <p className="font-semibold">Example Input JSON</p>
                            <div className="w-full my-4">
                                <CopyBlock
                                    text={codeSnippets.movieRecommendInput}
                                    language="json"
                                    showLineNumbers={false}
                                    theme={anOldHope}
                                    wrapLines={true}
                                    codeBlock
                                />
                            </div>
                            <p className="font-semibold">Summary</p>
                            <p>
                                This documentation outlines a systematic
                                approach to:
                            </p>
                            <ul className="my-2 list-decimal list-inside">
                                <li>Load and preprocess a dataset.</li>
                                <li>
                                    Define and train a recommendation model
                                    using TensorFlow.
                                </li>
                                <li>
                                    Track and log the training process with
                                    MLflow.
                                </li>
                                <li>
                                    Deploy the model using Docker and Vipas SDK.
                                </li>
                                <li>
                                    Provide an API endpoint for real-time
                                    predictions.
                                </li>
                            </ul>
                            <p className='my-2'>
                                This modular pipeline ensures high scalability
                                and ease of integration for real-world
                                applications.
                            </p>
                            <Navigation
                       previous={"Previous: TensorFlow Model Training and Deployment"} previousNav={"tensorflow-with-mlflow"}
                       next={"Next: TensorFlow-based image classification model using the CIFAR-10 dataset"}
                       nextNav={"tensorflow-image-classification"}
                   />
               </div>
               <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16">
                   {/* <p className="font-semibold my-8 ">On this page</p>
                   <ul className="border-t-2 py-4">
                       <li className="link-list ">
                           <a href="#supported-file-types" className="links">
                               Supported File Types
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#directory-structure" className="links">
                               Directory Structure
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#model-configuration" className="links">
                               Model Configuration
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#summary-workflow" className="links">
                               Summary Workflow
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#examples" className="links">
                               Examples
                           </a>
                       </li>
                   </ul> */}
               </div>
    </div>
   </>
  )
}

export default MovieRecommendation
